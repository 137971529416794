import React, { useState } from 'react';
import { Image, Modal } from 'react-bootstrap';
import './contenido.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Contenido = ({ object }) => {
  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
    setSelectedImage(null);
  };

  return (
    <>
      {object && (
        <div>
          {object.articulo.map((article, j) => (
            <div className="bg-white py-3 px-3 mb-5" key={j}>
              {article?.titulo && <h4 className="fs-2 fw-medium pb-4">{article.titulo}</h4>}
              <p className="text-justify lh-lg ps-3 pt-3">{article.descripcion}</p>
              {article.data.map((titlink, k) => (
                <ul className="list-group-item list-unstyled py-3 border-0" key={k}>
                  {titlink?.titulolink && (
                    <a href={titlink.url} target="_blank" rel="noopener noreferrer">
                      {titlink.titulolink}
                    </a>
                  )}
                  <div>
                    {titlink.docs.map((subtit, s) => (
                      <ul className="list-unstyled pb-2" key={s}>
                        <span>
                          <small className='p-0 ms-0'>
                            <i className={subtit.icono} style={{ width: '1mm', height: '1mm', color: 'var(--wine)' }}></i>
                          </small>

                          {subtit.url ? (
                            <a className="ps-3" href={subtit.url} target="_blank" rel="noopener noreferrer">
                              {subtit.subtitulolink}
                            </a>
                          ) : (
                            <span className="ps-3">
                              {subtit.subtitulolink}
                            </span>
                          )}
                          {/* <a className="ps-3" href={subtit.url} target="_blank" rel="noopener noreferrer">
                            {subtit.subtitulolink}
                          </a> */}
                          <div>
                          {subtit.datalist?.map((name, e) => (
                              name.link ? (
                                <ul className='list-group list-group-flush'>
                                  <li className='list-group-item pb-0 ps-4'>
                                    <a key={e} href={name.link} target="_blank" className='pb'>
                                    {name.nombre}</a>
                                  </li>
                                </ul>
                              ) : (
                                <ul className='list-group list-group-flush' key={e}>
                                  <li className='list-group-item pb-0 ps-4'>
                                    {name.nombre}
                                  </li>
                                </ul>
                              )
                            ))}
                          </div>
                        </span>
                        <div className="row">
                          {subtit.images?.map((ima, i) => (
                            <div className="col-md-4 col-xs-12 pt-1" key={i}>
                              <a onClick={() => handleClick(ima.img)} type="button">
                                <Image className="img-fluid shadow-1-strong rounded" src={ima.img} />
                              </a>
                            </div>
                          ))}
                        </div>
                      </ul>
                    ))}
                  </div>
                </ul>
              ))}
            </div>
          ))}
        </div>
      )}

      {/* Modal */}
      <Modal show={show} onHide={closeModal} centered style={{ maxWidth: '100%', width: '100%' }}>
          <Modal.Body>
            {selectedImage && <Image className="img-fluid shadow-1-strong rounded" src={selectedImage} />}
          </Modal.Body>
      </Modal>
    </>
  );
};

export default Contenido;