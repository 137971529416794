export const protocoloCeroObject = {
    'nombre': 'Protocolo Cero',
    'tipo': '3',
    'servidor': '1',
    'articulo': [
      {
        'titulo': '',
        'descripcion': 'En la Secretaría de Hacienda, queremos mantener espacios laborales seguros para todas y todos. Si estás siendo víctima de hostigamiento sexual y/o laboral o acoso sexual y/o laboral en la Secretaría de Hacienda, debes denunciarlo. Contacta a una persona consejera, quien te acompañará y guiará durante el proceso.',
        'data': [
          {
            'titulolink': '',
            'url': '',
            'docs': [
              {
                "icono": "fa-solid fa-chevron-right",
                'subtitulolink': 'Personas consejeras',
                'url': '../files/secretaria/protocoloCero/2025/Cartel Personas Consejeras.pdf'
              },
              {
                "icono": "fa-solid fa-chevron-right",
                'subtitulolink': 'Directorio personas consejeras',
                'url': '../files/secretaria/protocoloCero/2025/Directorio de Personas Consejeras.pdf'
              },
              {
                "icono": "fa-solid fa-chevron-right",
                'subtitulolink': 'Pronunciamiento de Cero Tolerancia',
                'url': '../files/secretaria/protocoloCero/Pronunciamiento de Cero Tolerancia.pdf'
              },
              {
                "icono": "fa-solid fa-chevron-right",
                'subtitulolink': 'Formato de primer contacto',
                'url': '../files/secretaria/protocoloCero/Formato de 1er Contacto.pdf'
              },
              {
                "icono": "fa-solid fa-chevron-right",
                'subtitulolink': 'Informe anual de Protocolo Cero',
                'url': '',
                'datalist': [
                  {
                    "nombre": "2024",
                    "link": "../files/secretaria/protocoloCero/2025/Informe Anual de Actividades 2024.pdf"
                  },
                  {
                    "nombre": "2023",
                    "link": "../files/secretaria/protocoloCero/Informe Anual.pdf"
                  }
                ]
              },
              {
                "icono": "fa-solid fa-chevron-right",
                'subtitulolink': 'Proceso de denuncia',
                'url': '../files/secretaria/protocoloCero/Proceso_de_Denuncia_Protocolo_Cero.pdf'
              },
              {
                'images': [
                  {
                    'img': '../assets/imgs/Protocolo Cero/protocoloCero01.jpg',
                  },
                  {
                    'img': '../assets/imgs/Protocolo Cero/protocoloCero02.jpg',
                  },
                  {
                    'img': '../assets/imgs/Protocolo Cero/protocoloCero03.jpg',
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
  