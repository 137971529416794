export const deinteresArray = [
    {
        "id": '1',
        "myclass": '',
        "seccion": [
            // {
            //     "titulo": 'TRAMITES EN LÍNEA',
            //     "data": [
            //         {
            //             "nombre": 'Refrendo',
            //             "url": 'https://atributariaw1.hidalgo.gob.mx/eSIT/P2_recaudacion/pago_tenencia/c_pago_tenencia.jsp?accion=capturar_ss'
            //         },
            //         {
            //             "nombre": 'Consulta y pago de referencias bancarias',
            //             "url": 'https://atributariaw1.hidalgo.gob.mx/eSIT/P2_recaudacion/consulta_referencia/c_consulta_referencia.jsp?accion=consultar_ss'
            //         },
            //         {
            //             "nombre": 'eSIT Móvil',
            //             "url": 'https://atributariaw2.hidalgo.gob.mx/eSIT/P9_inicio/teclado/c_teclado.jsp'
            //         }
            //     ]
            // },
            {
                "titulo": 'INFORMACIÓN DESTACADA',
                "data": [
                    {
                        "icono":"fa-solid fa-chevron-right",
                        "nombre": 'Transparencia Financiera',
                        "url": '/Transparencia'
                    },
                    // {
                    //     "icono":"fa-solid fa-chevron-right",
                    //     "nombre": 'Estados Financieros',
                    //     "url": '/Secretaría/EstadosFinancieros'
                    // },
                    // {
                    //     "icono":"fa-solid fa-chevron-right",
                    //     "nombre": 'Paquete Hacendario',
                    //     "url": '/Secretaría/PaqueteHacendario'
                    // },
                    {
                        "icono":"fa-solid fa-chevron-right",
                        "nombre": 'Información Financiera SRFT',
                        "url": 'https://hacienda.hidalgo.gob.mx/Servicios/InformacionFinancieraSRFT'
                    },
                    {
                        "icono":"fa-solid fa-chevron-right",
                        "nombre": 'Presupuesto Ciudadano 2025',
                        "url": 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/PorTema/3367/Presupuesto_Ciudadano__2025.pdf'
                    },
                    {
                        "icono":"fa-solid fa-chevron-right",
                        "nombre": 'Ejercicio del Presupuesto 2024',
                        "url": 'https://hacienda.hidalgo.gob.mx/Servicios/EjerciciodelPresupuesto2024'
                    },
                    {
                        "icono":"fa-solid fa-chevron-right",
                        "nombre": 'Paquete Hacendario',
                        "url": '/Secretaría/PaqueteHacendario'
                    },
                    // {
                    //     "icono":"fa-solid fa-chevron-right",
                    //     "nombre": 'Paquete Hacendario 2024',
                    //     "url": 'https://hacienda.hidalgo.gob.mx/Servicios/PaqueteHacendario2024'
                    // },
                    // {
                    //     "icono":"fa-solid fa-chevron-right",
                    //     "nombre": 'Paquete Hacendario 2023',
                    //     "url": 'https://hacienda.hidalgo.gob.mx/Servicios/PaqueteHacendario2023'
                    // },
                    {
                        "icono":"fa-solid fa-chevron-right",
                        "nombre": 'Cuenta Pública',
                        "url": '/InformeHaciendaPublica'
                    },
                    {
                        "icono":"fa-solid fa-chevron-right",
                        "nombre": 'Evaluación del Desempeño',
                        "url": '/Secretaría/EvaluaciondelDesempeño'
                    },
                    // {
                    //     "nombre": 'Presupuesto Ciudadano',
                    //     "url": ''
                    // },
                    // {
                    //     "icono":"fa-solid fa-chevron-right",
                    //     "nombre": 'IHDEP - PID',
                    //     "url": '/IHDEP'
                    // }
                ]
            },
        ]
    },
    {
        "id": '2',
        "myclass": 'd-flex align-items-stretch',
        "seccion": [
            {
                "titulo": 'CONSULTA',
                "data": [
                    {
                        "icono":"fa-solid fa-chevron-right",
                        "nombre": 'Portal Tributario',
                        "url": 'https://portaltributario.hidalgo.gob.mx/'
                    },
                    {
                        "icono":"fa-solid fa-chevron-right",
                        "nombre": 'Consulta tu factura',
                        "url": 'https://efinanzas.hidalgo.gob.mx/SIFACP/'
                    },
                    {
                        "icono":"fa-solid fa-chevron-right",
                        "nombre": 'Consulta y pago de referencias bancarias',
                        "url": 'https://atributariaw1.hidalgo.gob.mx/eSIT/P2_recaudacion/consulta_referencia/c_consulta_referencia.jsp?accion=consultar_ss'
                    },
                    {
                        "icono":"fa-solid fa-chevron-right",
                        "nombre": 'Remates en línea',
                        "url": 'https://hacienda.hidalgo.gob.mx/Servicios/RematesenLinea'
                    },
                    // {
                    //     "icono":"fa-solid fa-chevron-right",
                    //     "nombre": 'Impuestos y Derechos Hidalgo',
                    //     "url": '/Secretaría/ImpuestosyDerechos'
                    // },
                    {
                        "icono":"fa-solid fa-chevron-right",
                        "nombre": 'Guía rápida del Gasto de Inversión',
                        "url": 'https://hacienda.hidalgo.gob.mx/files/secretaria/programacionFinanciera/Folleto%20Gastos%20de%20Inversion%202023.pdf'
                    },
                    // {
                    //     "icono":"fa-solid fa-chevron-right",
                    //     "nombre": 'Impuestos y Derechos Hidalgo',
                    //     "url": '/Secretaría/ImpuestosyDerechos'
                    // },
                    {
                        "icono":"fa-solid fa-chevron-right",
                        "nombre": 'Unidad de Inversiones',
                        "url": '/Micrositios/UnidaddeInversiones'
                    },
                    {
                        "icono":"fa-solid fa-chevron-right",
                        "nombre": 'Protocolo Cero',
                        "url": '/Servicios/ProtocoloCero'
                    },
                    // {
                    //     "icono":"fa-solid fa-chevron-right",
                    //     "nombre": 'eHacienda',
                    //     "url": 'https://ehacienda.hidalgo.gob.mx/SIAUUV2/'
                    // },
                    // {
                    //     "icono":"fa-solid fa-chevron-right",
                    //     "nombre": 'Evaluación del Desempeño',
                    //     "url": '/Secretaría/EvaluaciondelDesempeño'
                    // }
                ]
            }
        ]
    }
]