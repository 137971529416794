export const agenda = [

    { 
        id: 0,
        title: 'Fue un gusto participar en la reunión de trabajo virtual con el titular de @SefinaGuerrero, Raymundo Segura Estrada, en la cual compartimos buenas prácticas y experiencias que tiene el @gobiernohidalgo en el Sistema de Recursos Federales Transferidos #SRFT.',
        place: 'Pachuca de Soto',
        description: '1664422960306716673?s=20',
        start: '2023-06-01',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Una de las  encomiendas del gobernador @juliomenchaca_ es construir espacios funcionales y dignos para bienestar del pueblo. Por ello junto a la titular de @Salud_Hidalgo, @ZoraydaRoblesB, el secretario de @Contraloria_Hgo, Álvaro Bardales y el subsecretario de @SIPDUS, @JorgeAlbertoR_ recorrimos el nuevo #HospitalGeneral de #Pachuca que brindará a las y los hidalguenses salud de calidad así como el servicio que se merecen. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1665196037370150912?s=20',
        start: '2023-06-03',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '¡La lucha por el cambio verdadero para nuestro estado, triunfó hace un año! Es un honor ser parte del gabinete de gobierno que encabeza @juliomenchaca_, junto a él trabajamos con el firme compromiso de impulsar el máximo potencial de #Hidalgo para beneficiar a cada hidalguense.',
        place: 'Pachuca de Soto',
        description: '1665830774963093507?s=20',
        start: '2023-06-05',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'La transformación de #Hidalgo está en proceso ¡Vamos a recuperar su grandeza! A 9 meses de administración del gobernador @juliomenchaca_, evaluamos y trabajamos estrategias que detonen el desarrollo para que las familias tengan una mejor calidad de vida. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1666583301421768705?s=20',
        start: '2023-06-07',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'El trabajo conjunto nos da la oportunidad de lograr más acciones que mejoren las condiciones de vida para #ElHidalgoQueQueremos. Con la @ONU_es y  @SE_mx, el gobernador @juliomenchaca_ presentó la Ruta Hidalgo 2030, Transformación para un Desarrollo Sostenible e Inclusivo. Desde nuestras respectivas trincheras el @gobiernohidalgo está comprometido a impulsar esa transformación mediante estrategias encaminadas a los #ODS, todas y todos colaboremos por un #DesarrolloSostenible',
        place: 'Pachuca de Soto',
        description: '1668408806999752704?s=20',
        start: '2023-06-12',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En reunión de trabajo con personal de la Dirección General de Monitoreo y Seguimiento al Recurso Público de @Hacienda_Hgo, analizamos estrategias que nos permitan verificar con mayor fluidez la correcta aplicación del presupuesto. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1669190382435946496?s=20',
        start: '2023-06-14',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'El gobernador  @juliomenchaca_ nos ha encargado fortalecer alianzas estratégicas que aporten en la transformación de nuestro estado. Con representantes de @NafinOficial y @bancomext analizamos los programas de apoyo que tiene la banca de desarrollo del gobierno federal, en beneficio del sector productivo que ayude a impulsar el máximo potencial de #Hidalgo. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1671334790870491136?s=20',
        start: '2023-06-20',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Con el objetivo de fortalecer las funciones y acciones llevadas a cabo por @Hacienda_Hgo, estamos trabajando en mejoras generalizadas para el reglamento interno. Este instrumento busca garantizar el orden institucional, tal como se ha comprometido el gobernador @juliomenchaca_. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1672102187156004865?s=20',
        start: '2023-06-22',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En congruencia con los objetivos de esta administración encabezada por nuestro gobernador @juliomenchaca_ , continuamos trabajando en fortalecer el marco institucional de @Hacienda_Hgo. Consolidamos las condiciones jurídicas pertinentes, para que a través de las  acciones y funciones abonemos a la transformación de #ElHidalgoQueQueremos. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1672342556464611328?s=20',
        start: '2023-06-23',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Analizamos nuevas estrategias que garanticen el ejercicio del presupuesto acorde a las necesidades de la población; con Juan Evel Chávez, titular de @CAASIM damos seguimiento a los proyectos enfocados a mejorar los servicios del agua.',
        place: 'Pachuca de Soto',
        description: '1672434464343949312?s=20',
        start: '2023-06-23',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'La salud de todas y todos es prioridad; es por ello, que redoblamos esfuerzos para que el pueblo de #Hidalgo tenga acceso a instalaciones dignas y seguras en el nuevo #HospitalGeneral Con la y los titulares de @Salud_Hidalgo, Zorayda Robles; @Contraloria_Hgo, Álvaro Bardales; @CAASIM, Juan Evel Chávez y el subsecretario de @SIPDUS; Jorge Reyes, damos seguimiento a diversos temas de este proyecto para realizar mejoras a los servicios que pronto brindará a miles de familias hidalguenses. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1672716254606442502?s=20',
        start: '2023-06-24',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Por el bien de Hidalgo #PrimeroElPueblo; es por ello, que en coordinación con Simey Olvera, titular de @Bienestar_Hgo y de @amzenteno, titular de @GobMXHidalgo trabajamos en el seguimiento de las estrategias adecuadas para fortalecer el programa de la Pensión Universal para Personas con Discapacidad. En unidad consolidamos un proyecto que mejorará las condiciones de este sector vulnerable, es una prioridad para el gobernador @juliomenchaca_ que todas y todos tengan oportunidades de desarrollo.',
        place: 'Pachuca de Soto',
        description: '1673500609708953600?s=20',
        start: '2023-06-26',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Ejercer las atribuciones, funciones y obligaciones de nuestra competencia de acuerdo con la normatividad, es toral para atender las necesidades del pueblo y optimizar los recursos públicos. Con las subsecretarías de @Hacienda_Hgo continuamos en la revisión del reglamento interno de esta dependencia, que nos permita acciones más efectivas para avanzar en los objetivos de #ElHidalgoQueQueremos. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1673532801209991169?s=20',
        start: '2023-06-26',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Para el @gobiernohidalgo, a través de @Hacienda_Hgo, es un gusto participar en el X Foro Iberoamericano de Coordinación Presupuestaria, Financiera y Fiscal Intergubernamental. Temas como la “Situación Macroeconómica y Fiscal Internacional y Regional” nos permite nuevas ópticas en la toma de decisiones que beneficien en un mejor ejercicio del presupuesto para atender oportunamente las necesidades del pueblo.',
        place: 'Pachuca de Soto',
        description: '1674174667496538116?s=20',
        start: '2023-06-28',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Debatir sobre las mejores prácticas que contribuyen a la eficacia y eficiencia de las haciendas públicas fue parte de los temas en los que participé esta mañana junto a mis homólogos de otros estados en la "CCCLII Reunión de la Comisión Permanente de Funcionarios Fiscales" en #Guadalajara. En este evento, también tuvimos el privilegio de escuchar conferencias magistrales de expertos invitados del X Foro Iberoamericano de Coordinación Presupuestaria, Financiera y Fiscal Intergubernamental. Las aportaciones de estos expertos son de gran valor para @Hacienda_Hgo, ya que amplían nuestras herramientas y conocimientos para lograr mejores resultados y garantizar el bienestar del pueblo.',
        place: 'Pachuca de Soto',
        description: '1674493585604087809?s=20',
        start: '2023-06-29',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Agradezco al @GobiernoJalisco y al @INDET por invitar a, @Hacienda_Hgo para participar en la "CCCLII Reunión de la Comisión Permanente de Funcionarios Fiscales" y el "X Foro Iberoamericano de Coordinación Presupuestaria, Financiera y Fiscal Intergubernamental" en #Guadalajara durante tres días. Intercambiamos experiencias y propusimos medidas para abordar los desafíos actuales y unificar esfuerzos entre las entidades en busca de finanzas sanas. La colaboración intergubernamental es fundamental para el desarrollo de Hidalgo y mejorar las condiciones de vida de las familias hidalguenses, tal como lo ha encomendado el gobernador @juliomenchaca_.#PrimeroElPueblo',
        place: 'Guadalajara, Jalisco',
        description: '1674924007789850630?s=20',
        start: '2023-06-30',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'A 10 meses de iniciar la transformación de #ElHidalgoQueQueremos, bajo el liderazgo del gobernador @juliomenchaca_, seguimos trabajando en unidad y consolidando un gobierno cercano día a día. De manera transversal, evaluamos acciones y proyectos que garanticen el bienestar de las familias. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1676750478158209024?s=20',
        start: '2023-07-05',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'El trabajo interinstitucional nos permite asegurar el cumplimiento de los objetivos que tienen los recursos públicos para consolidar el bienestar social de #ElHidalgoQueQueremos. Con los titulares Álvaro Bardales, de la @Contraloria_Hgo; @_AlejandroSanG, de la @SIPDUS y Orlando Ángeles, de la @OficialiaMa compartimos los avances  y el seguimiento de distintos proyectos para tener mejores resultados en beneficio del pueblo.',
        place: 'Pachuca de Soto',
        description: '1677008872899239940?s=20',
        start: '2023-07-06',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Las acciones de transformación de #ElHidalgoQueQueremos las fortalecemos con el trabajo coordinado. En reunión con los secretarios @_AlejandroSanG, de @SIPDUS; Álvaro Bardales, de @Contraloria_Hgo; @mtellov, de @PlaneacionHgo y Orlando Ángeles, de @OficialiaMa, realizamos el monitoreo, evaluación y seguimiento de los proyectos estratégicos. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1677049850855141381?s=20',
        start: '2023-07-06',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Junto a titulares de las distintas secretarías que conformamos el gabinete del @gobiernohidalgo, realizamos una evaluación de los proyectos en marcha. Nos aseguramos de que los recursos se utilicen de manera eficiente y transparente, siguiendo las instrucciones de nuestro gobernador @juliomenchaca_. Estas acciones son fundamentales para impulsar el desarrollo de #Hidalgo y mejorar el bienestar de las familias hidalguenses',
        place: 'Pachuca de Soto',
        description: '1677519312947494914?s=20',
        start: '2023-07-07',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Como abogada, fue un gusto acompañar al gobernador @juliomenchaca_ en la entrega del Mérito Jurídico "Alfonso Cravioto Mejorada" al licenciado Jaime Daniel Baños Paz, en reconocimiento a su labor y compromiso en esta profesión. ¡Felicito a mis colegas abogados! Sigamos trabajando juntos para construir un mundo que garantice justicia y una vida digna para todas y todos. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1679249763101728768?s=20',
        start: '2023-07-12',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Bajo un esquema de gobierno eficiente, que facilite el seguimiento y avance del presupuesto, tal como lo ha instruido el gobernador @juliomenchaca_, trabajamos en conjunto con la magistrada presidenta @Rebeca_AladroE del @PJEHidalgo. Nuestro objetivo es implementar distintas estrategias que garanticen una mejor gestión de los recursos, y promover la justicia para el pueblo de #Hidalgo.',
        place: 'Pachuca de Soto',
        description: '1679338851095937024?s=20',
        start: '2023-07-12',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Con un presupuesto enfocado a las necesidades del pueblo, estamos transformando #Hidalgo. Me reuní con quienes encabezan las distintas áreas de @Hacienda_Hgo para trabajar y dar continuidad a los detalles del proyecto de #Presupuesto2024 que, como lo instruye el gobernador @juliomenchaca_, busca beneficiar a cada hidalguense e impulsar el desarrollo de nuestro estado.',
        place: 'Pachuca de Soto',
        description: '1679973982538280966?s=20',
        start: '2023-07-14',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'El trabajo coordinado es la base para construir #ElHidalgoQueQueremos. Junto a la titular de @SaludHidalgo, @ZoraydaRoblesB e integrantes de dicha dependencia, analizamos las mejores condiciones para atender en materia de salud al pueblo de #Hidalgo, así como la correcta asignación y aplicación de los recursos que garanticen #ResultadosQueMejoranVidas.',
        place: 'Pachuca de Soto',
        description: '1680085384301015041?s=20',
        start: '2023-07-14',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Para consolidar #ResultadosQueMejoranVidas seguimos de cerca cada acción que se realiza en el avance del nuevo #HospitalGeneral de Pachuca con @Salud_Hidalgo, @Contraloria_Hgo y @SIPDUs. Desde @Hacienda_Hgo administramos la eficiente aplicación de los recursos del pueblo y para el pueblo; con transparencia y rendición de cuentas para el @gobiernohidalgo #PrimeroElPueblo.',
        place: 'Pachuca de Soto',
        description: '1680334438511570945?s=20',
        start: '2023-07-15',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '¡Enhorabuena! El @gobiernohidalgo se convierte en el primer estado que profesionaliza a las y los servidores públicos con la puesta en marcha de la Academia de la Transformación; esta plataforma educativa nos permitirá afrontar con mayores conocimientos los nuevos retos. Como servidores públicos tenemos el firme compromiso de mejorar para prestar un servicio digno, humano y de calidad que merece el pueblo hidalguense  para construir el #HidalgoQueQueremos.',
        place: 'Pachuca de Soto',
        description: '1681068336807915521?s=20',
        start: '2023-07-17',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '¡Hidalgo se transforma con #ResultadosQueMejoranVidas! Con un presupuesto que invierte a través de @CAASIM y @SIPDUS en el mantenimiento y mejora de infraestructura hídrica para que todas y todos tengan agua en sus hogares, el gobernador @juliomenchaca_ hace efectivo el compromiso de generar acciones de bienestar donde es #PrimeroElPueblo.',
        place: 'Pachuca de Soto',
        description: '1681797003884351490?s=20',
        start: '2023-07-19',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '¡Trabajamos para el mejoramiento del entorno por #ElHidalgoQueQueremos! En este gobierno la planeación es herramienta valiosa para el avance de nuestro estado; de ahí la importancia que #Hidalgo cuente con los Atlas de Riesgos Municipales, que proporcionen información para prevenir los peligros que pueden afectar a la población y a la infraestructura. Mediante un trabajo interinstitucional, @Hacienda_Hgo se suma a los acuerdos para una estrategia conjunta que encabeza @mtellov, de @PlaneacionHgo, así como los secretarios Guillermo Olivares, de @SEGOBHidalgo y @natycastrejonv, de @SEPHidalgo, para elaborar y actualizar estos instrumentos en beneficio de las y los hidalguenses.',
        place: 'Pachuca de Soto',
        description: '1682194228255027200?s=20',
        start: '2023-07-20',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Somos testigos del compromiso con el pueblo para brindarle atención en espacios dignos. Hoy, se inauguraron las nuevas oficinas de la representación en Hidalgo de la Procuraduría Agraria, y el gobernador @juliomenchaca_ junto al procurador agrario, Andrés Velázquez Vázquez, reiteraron su colaboración interinstitucional. El objetivo es detonar el máximo potencial con el fortalecimiento de acciones que garanticen los derechos de quienes trabajan en el campo hidalguense. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1682655045060288514?s=20',
        start: '2023-07-22',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Tenemos la responsabilidad de trabajar de forma intersectorial para lograr más #ResultadosQueMejoranVidas. Con los secretarios @_AlejandroSanG , de @SIPDUS y Álvaro Bardales, de l @Contraloria_Hgo realizamos la revisión de avance de aplicación de recursos públicos para distintos proyectos, con el objetivo de que exista una efectividad administrativa que coadyuve a un mejor aprovechamiento de estos en favor de las familias hidalguenses. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1683916505602473984?s=20',
        start: '2023-07-25',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '¡Mejorando procesos administrativos y recaudatorios para una mejor atención ciudadana! 📊 Con el subsecretario de Ingresos, Guillermo Huerta Meneses, trabajamos en acciones que fortalecen la hacienda pública y cumplen los objetivos gubernamentales, atendiendo las necesidades de las familias hidalguenses. ¡Junto al gobernador @juliomenchaca_, consolidamos una administración eficaz, transparente y honesta que busca #ResultadosQueMejoranVidas!',
        place: 'Pachuca de Soto',
        description: '1684316206629806081?s=20',
        start: '2023-07-26',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Con Jorge Valverde Islas, titular de la @ASEH_Hgo, dimos inicio al curso–taller “Programa de Capacitación y Profesionalización en Armonización Contable 2023”. Para el gobernador @juliomenchaca_ es crucial que los entes públicos que manejen recursos cuenten con los conocimientos y habilidades necesarias para aplicar correctamente la Ley General de Contabilidad Gubernamental y los acuerdos del Consejo Nacional de Armonización Contable (CONAC). Así garantizamos más transparencia, rendición de cuentas y una gestión financiera efectiva y eficiente para #ElHidalgoQueQueremos.',
        place: 'Pachuca de Soto',
        description: '1686109153125609472?s=20',
        start: '2023-07-31',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Con acciones efectivas estamos otorgándole al pueblo #ResultadosQueMejoranVidas.Como parte del compromiso del @gobiernohidalgo en beneficio de las y los hidalguenses, el gobernador @juliomenchaca_ , junto al secretario @MeyerFalcon, instauró la Comisión de Ordenamiento Metropolitano de la Zona Metropolitana de Pachuca, donde tomé protesta como integrante. Ser parte de estas actividades y trabajar de manera coordinada con la @SEDATU_mx, impulsa el crecimiento sostenible de nuestras ciudades.',
        place: 'Pachuca de Soto',
        description: '1686880470921801728?s=20',
        start: '2023-08-02',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Junto al gobernador @juliomenchaca_ y titulares de las Secretarías que integran al @gobiernohidalgo, nos reunimos para compartir los avances y resultados que hemos logrado durante estos 11 meses de administración. Seguimos avanzando para otorgarle al pueblo #ResultadosQueMejoranVidas.',
        place: 'Pachuca de Soto',
        description: '1687992469659504641?s=20',
        start: '2023-08-05',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Escuchar las palabras de alguien que ha transformado la vida de muchas personas en el mundo es inspirador para nuestra mente y corazón. Fue un placer acompañar a la #FamiliaDIFH, encabezada por Edda Vite, presidenta del Patronato, en la conferencia magistral brindada por la Asociación Nacional Treacher Collins LIAM México y Jono Lancaster, titulada "Adopción y discapacidad". Sus palabras sobre este tema transmiten fuerza y esperanza, alentándonos a seguir luchando por el bienestar de #ElHidalgoQueQueremos. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1688719835604123648?s=20',
        start: '2023-08-07',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Mediante un trabajo transversal colaboramos en diseñar estrategias mejor orientadas a obtener #ResultadosQueMejoranVidas y generar bienestar para las familias como lo ha instruido el gobernador @juliomenchaca_ .Con el titular de @PlaneacionHgo,  @mtellov, analizamos distintos proyectos que realizamos de manera conjunta rumbo a la construcción del próximo presupuesto 2024.',
        place: 'Pachuca de Soto',
        description: '1689109597573836800?s=20',
        start: '2023-08-08',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Fortalecer desde el presupuesto los programas que impulsen nuestro turismo es fundamental para incentivar la economía de la entidad, para ello trabajamos de manera coordinada con la @SECTURHidalgo. ¡#HidalgoTieneAlgo que mostrar en sus pueblos mágicos y otros rincones de su territorio, juntos consolidamos #ElHidalgoQueQueremos!',
        place: 'Pachuca de Soto',
        description: '1689404049462808576?s=20',
        start: '2023-08-09',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Con los recursos presupuestados a @Salud_Hidalgo trabajamos en ampliar acciones que garanticen el acceso a los servicios de salud. El gobernador @juliomenchaca_ nos ha instruido maximizar el uso eficiente de los recursos para continuar entregando #ResultadosQueMejoranVidas. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1689480742030417920?s=20',
        start: '2023-08-09',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Gracias al trabajo coordinado que realizamos entre el gobierno federal y estatal, como lo ha instruido el gobernador @juliomenchaca_, se consolidan beneficios en materia de salud para las y los hidalguenses. Con mis compañeros secretarios @MemoOlivares_ , de @SEGOBHidalgo y @ZoraydaRoblesB, de @Salud_Hidalgo mantuvimos una mesa de trabajo con @zoerobledo, titular de @Tu_IMSS y Alejandro Calderón Alipi, titular de IMSS- Bienestar,  en la cual revisamos acciones para fortalecer el sistema de salud. #ResultadosQueMejoranVidas #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1690186563882213377?s=20',
        start: '2023-08-11',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Con las y los coordinadores de los Centros Regionales de @Hacienda_Hgo, ubicados en 13 municipios, dialogamos sobre los nuevos proyectos en los que trabajaremos en los siguientes meses. El compromiso de las y los servidores públicos es invaluable para construir el #HidalgoQueQueremos y suma a las acciones del gobernador @juliomenchaca_ para entregar #ResultadosQueMejoranVidas.',
        place: 'Pachuca de Soto',
        description: '1690214485384953857?s=20',
        start: '2023-08-11',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Bajo el liderazgo del gobernador @juliomenchaca_, hoy estamos en una ruta de una profunda transformación, donde la suma de esfuerzos y voluntades puestas al servicio del pueblo nos han permitido concretar #ResultadosQueMejoranVidas. Bajo esta dinámica, con @mtellov, titilar de @PlaneacionHgo; Mónica Mixtega, de la @medioambiente_H y Lyzbeth Robles, de @MovilidadHgo analizamos distintos proyectos estratégicos que contribuyan al mejoramiento del entorno y el bienestar social. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1691291284122542080?s=20',
        start: '2023-08-14',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Compartimos la responsabilidad de redoblar esfuerzos para consolidar la transformación de #ElHidalgoQueQueremos. Con @LizQuintanarG, de la @SECTURHidalgo y @mtellov, de la @PlaneacionHgo, trabajamos en la integración y planeación de proyectos turísticos necesarios para desarrollo en este sector, así como la eficiente distribución de recursos para dar atención a estos #RecursosDelPuebloYParaElPueblo. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1691552665732542619?s=20',
        start: '2023-08-15',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Para impulsar la economía y desarrollo de #ElHidalgoQueQueremos, y así generar mejores condiciones de vida, trabajamos de manera cercana y coordinada con cada dependencia. En reunión con @mtellov, de @PlaneacionHgo; @OscarJavierGnz2, de @strabajohgoy Napoleón González, de @AgriculturaHgo analizamos las acciones necesarias para el desarrollo de programas que propicien la capacitación y adiestramiento para los sectores productivos, así como las estrategias para tener un campo más próspero y sustentable. #ResultadosQueMejoranVidas #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1691634352294346902?s=20',
        start: '2023-08-15',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En conjunto con la @SEGOBHidalgo, @medioambiente_H, @Sedeco_Hgo, @OficialiaMa y @CAASIM, nos reunimos con la calificadora crediticia @MoodysLatAm, a fin de que la entidad sea evaluada y obtenga la calificación correspondiente a la situación económica y financiera del estado.',
        place: 'Pachuca de Soto',
        description: '1692332850727493896?s=20',
        start: '2023-08-17',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En seguimiento al compromiso del gobernador @juliomenchaca_ para aplicar de forma eficiente y transparente los recursos recaudados por el canje de placas, con el secretario @_AlejandroSanG,  titular de la @SIPDUS, analizamos lo presupuestado y programando de dicho recursos de acuerdo a las necesidades de cada uno de los municipios. Se ha priorizado la rehabilitación, modernización y construcción de carreteras; y la rehabilitación, sustitución y construcción de nuevas líneas de conducción de agua potable, para mejorar las condiciones de infraestructura que impulsen el crecimiento económico y el desarrollo social. #ResultadosQueMejoranVidas #RecursosDelPuebloYParaElPueblo #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1692659640973115627?s=20',
        start: '2023-08-18',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Garantizar a las familias hidalguenses mejores condiciones de vida requiere el esfuerzo conjunto; es por ello, que en coordinación con @mtellov, titular de @PlaneacionHgo, la @SEPHidalgoy @Salud_Hidalgo trabajamos en los distintos proyectos estratégicos a fin de tener una proyección de inversión de los recursos para 2024, que se requerirán para cada uno de ellos. #PrimeroElPueblo #ResultadosQueMejoranVidas',
        place: 'Pachuca de Soto',
        description: '1692926601854415155?s=20',
        start: '2023-08-19',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Ha sido muy grato y provechoso participar en el Encuentro Nacional con la Unión Europea, donde compartimos con otras entidades las buenas prácticas del Modelo Hacendario Estatal para la Cohesión Social. La hacienda pública tiene grandes retos para recaudar impuestos, mejorar la calidad y eficiencia del gasto público, de ahí la importancia de  intercambiar estrategias exitosas que nos permitan asegurar la correcta administración y aplicación de los recursos asignados, ubicando en primera instancia a la población más vulnerable. #NoDejarANadieAtras #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1696703688767656440?s=20',
        start: '2023-08-20',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Con @mtellov, titular de la @PlaneacionHgo; @SNietoCastillo, encargado del despacho de la @PGJE_Hidalgo y personal de la @Contraloria_Hgo, llevamos a cabo el análisis de sus proyectos de inversión para el 2024. El gobernador @juliomenchaca_ nos ha pedido administrar los recursos públicos de forma eficiente a fin de potenciar el impacto económico y social de estos y se generen mejores condiciones de vida. https://x.com/Esther_RamirezV/status/1693823155310141585?s=20#PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1693823155310141585?s=20',
        start: '2023-08-21',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '#ElHidalgoQueQueremos es honesto y transparente;  trabajamos en una eficiente distribución de los recursos destinados a los proyectos de gasto de inversión 2024, por ello, revisamos con @mtellov, titular de @PlaneacionHgo las propuestas presentadas por Orlando Ángeles, de @OficialiaMa y @taniameza, de @CulturaHidalgo. Cada secretaría permanecerá, como lo ha instruido el gobernador @juliomenchaca_, cercana al pueblo para responder a las principales necesidades con estos proyectos #RecursosDelPuebloYParaELPueblo. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1694162409533915392?s=20',
        start: '2023-08-22',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Mediante la planeación y análisis de los proyectos de Gasto de Inversión 2024, de cada dependencia, el gobernador @juliomenchaca_ nos ha instruido trabajar para ejercer el máximo potencial de los recursos públicos en beneficio de las familias hidalguenses. Junto a @mtellov, titular de @PlaneacionHgo, evaluamos los distintos proyectos presentados por @MemoOlivares_, titular de la @SEGOBHidalgo y de los diferentes entes públicos que integran la dependencia, a fin de identificar aquellos que son estratégicos para dar respuesta a las necesidades prioritarias. #RecursosDelPuebloYParaElPueblo #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1694518574855954585?s=20',
        start: '2023-08-23',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Junto a @MemoOlivares_, #OrlandoÁngeles, @AlvaroBardales2 y @JorgeAlbertoR_ acompañamos al gobernador @juliomenchaca_ a la demolición de la “Casa de Gobierno”, un lugar que por años representó el privilegio de unos cuantos. Gracias a esta acción le devolvemos al pueblo un espacio para atenderles y escucharles. En el @gobiernohidalgo estamos transformando nuestro estado con #ResultadosQueMejoranVidas.',
        place: 'Pachuca de Soto',
        description: '1695232799278612706?s=20',
        start: '2023-08-25',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '¡#RecursosDelPuebloYParaElPueblo! Fue un gusto acompañar al gobernador @juliomenchaca_ en la entrega de unidades móviles para el servicio público y toneladas de asfalto que donó @Pemex al @gobiernohidalgo.',
        place: 'Pachuca de Soto',
        description: '1695608781420233133?s=20',
        start: '2023-08-26',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '¡El desarrollo sostenible, continuo e inclusivo se fortalece desde los presupuestos públicos alineados a los #ODS! Para el @gobiernohidalgo, a través de @HaciendaHgo, es relevante participar en el Encuentro Nacional con la Unión Europea, que se realiza en #Morelos. En este acto, donde damos continuidad a los trabajos del Modelo Hacendario Estatal para la Cohesión Social, se dio a conocer el programa #EU4Equity, el cual mediante diferentes acciones en las haciendas estatales se suman al propósito de "no dejar a nadie atrás", promesa central y transformadora de la #Agenda2030.',
        place: 'Pachuca de Soto',
        description: '1696323197254480250?s=20',
        start: '2023-08-28',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'La transformación de Hidalgo se enfoca en la atención de los sectores más vulnerables; por ello, con @ssimey, titular de @Bienestar_Hgo y  @PlaneacionHgo revisamos los proyectos de Gasto de Inversión 2024, que serán significativos para generar bienestar a las familias hidalguenses. #ResultadosQueMejoranVidas',
        place: 'Morelos',
        description: '1697455696860594663?s=20',
        start: '2023-08-31',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '#Hidalgo se transforma y el gobernador @juliomenchaca_ reitera el compromiso de seguir trabajando en beneficio de los sectores más vulnerables, con él #PrimeroElPueblo. Hoy tenemos una nueva forma de gobernar, donde la cercanía, transparencia y honestidad son indispensables para poner orden; a un año de gobierno tenemos #ResultadosQueMejoranVidas para el bienestar de todas y todos. #JulioMenchacaCumple #1erInformeHidalgo',
        place: 'Pachuca de Soto',
        description: '1699194676450939270?s=20',
        start: '2023-09-05',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Me reuní con @SNietoCastillo, encargado del despacho de la @PGJE_Hidalgo para dar seguimiento a los distintos proyectos que buscan fortalecer la procuración de justicia y así continuar trabajando siempre en beneficio de #PrimeroElPueblo.',
        place: 'Pachuca de Soto',
        description: '1699584834929062113?s=20',
        start: '2023-09-06',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'El compromiso con las y los hidalguenses en materia de salud es mejorar la atención médica; por ello, con @Salud_Hidalgo revisamos y analizamos los distintos proyectos que se integrarán en el próximo presupuesto 2024. #PrimeroElPueblo #RecursosDelPuebloYParaElPueblo',
        place: 'Pachuca de Soto',
        description: '1699626810869240144?s=20',
        start: '2023-09-06',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Agradezco el compromiso y esfuerzo continuo que hace posible lograr cambios. En reunión con parte del equipo de trabajo reiteré como lo ha dicho el gobernador @juliomenchaca_ que la suma de esfuerzos es la clave para lograr #ElHidalgoQueQueremos.',
        place: 'Pachuca de Soto',
        description: 'https://twitframe.com/show?url=1699654460790739426?s=20',
        start: '2023-09-06',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Agradezco el buen trabajo y coordinación con @OficialiaMa, quienes hicieron la entrega de chalecos institucionales para las y los servidores públicos que integramos @Hacienda_Hgo. Con estas acciones que fortalecen la identidad y el sentido de pertenencia, somos agentes de esta transformación, otorgando servicios a las y los hidalguenses de forma respetuosa, eficiente y oportuna, para brindar #ResultadosQueMejoranVidas.',
        place: 'Pachuca de Soto',
        description: '1699877251519484326?s=20',
        start: '2023-09-07',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En representación del gobernador @juliomenchaca_, acudí al evento cívico conmemorativo del 255 aniversario del natalicio de una de las heroínas de nuestra Independencia Doña Josefa Ortiz de Domínguez, a quien recordamos como una gran mujer de convicciones firmes por la justicia social, siendo partícipe de la gran nación que somos.',
        place: 'Pachuca de Soto',
        description: '1700229679985131951?s=20',
        start: '2023-09-08',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Junto a los titulares de @Sedeco_Hgo, @carekad y de @PlaneacionHgo, @mtellov, analizamos los proyectos de Gasto de Inversión del 2024; con el objetivo de seguir construyendo #ElHidalgoQueQueremos e impulsar el crecimiento económico de nuestro estado con transparencia y honestidad, beneficiando a las y los hidalguenses.',
        place: 'Pachuca de Soto',
        description: '1700264536178069889?s=20',
        start: '2023-09-08',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Con el objetivo de contribuir al desempeño y trabajo del personal operativo y técnico que colabora en la Dirección General de la Auditoría Fiscal de @Hacienda_Hgo, esta tarde entregamos equipo de cómputo. Contar con este equipo informático permitirá la modernización de nuevas herramientas y sistemas, lo que facilitará la eficiencia en el servicio de esta área en beneficio de la ciudadanía.',
        place: 'Pachuca de Soto',
        description: '1701379336819949981?s=20',
        start: '2023-09-11',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Los recursos del pueblo deben ser invertidos en obras y acciones que den una respuesta eficaz a las necesidades, es por ello que, con Juan Evel Chávez, titular de la @CAASIM revisamos los proyectos hídricos del Gasto de Inversión 2024 que serán prioritarios para el estado.',
        place: 'Pachuca de Soto',
        description: '1701465586759254459?s=20',
        start: '2023-09-11',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Me reuní de manera virtual con las y los titulares de Organismos Descentralizados del sector educativo del @gobiernohidalgo; acorde a las instrucciones del gobernador @juliomenchaca, desde @HaciendaHgo, trabajamos en la integración del Anteproyecto de Presupuesto 2024, el cual debe ser austero y orientado al bienestar del pueblo. #PrimeroElPueblo #RecursosDelPuebloYParaElPueblo',
        place: 'Pachuca de Soto',
        description: '1701688118720569839?s=20',
        start: '2023-09-12',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Las acciones y obras del Gasto de Inversión están alineados a los objetivos del Plan Estatal de Desarrollo, por lo que es fundamental el trabajo conjunto con todas las dependencias estatales y organismos. Con los titulares @mtellov, de @PlaneacionHgo; @_AlejandroSanG, de @SIPDUS; Juan Evel Chávez, de @CAASIM; Juan Carlos Chávez, de @CEAA_HGO y Jorge Arturo Castañeda, de @Cevi_Hidalgo, trabajamos coordinadamente para analizar cada proyecto e identificar los prioritarios para lograr la transformación de Hidalgo. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1701734615151530160?s=20',
        start: '2023-09-12',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Con el objetivo de seguir analizando los proyectos de Gasto de Inversión para el año 2024, sostuve una reunión con los titulares de @PlaneacionHgo, @mtellov, @Sedeco_Hgo, @carekad y @SIPDUS, @_AlejandroSanG. Coincidimos en que para potenciar el desarrollo de nuestro estado, es fundamental continuar impulsando la creación de nuevos parques industriales que atraigan inversiones y beneficien a la población de #Hidalgo.',
        place: 'Pachuca de Soto',
        description: '1701748547127763309?s=20',
        start: '2023-09-12',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Continuamos con la capacitación para las y los titulares de Organismos Descentralizados del @gobiernohidalgo; trabajamos en los ajustes de techos presupuestales con base en la política de austeridad y bienestar social, conforme al Plan Estatal de Desarrollo que el gobernador @juliomenchaca_ha puesto en marcha para la transformación de nuestro estado #PrimeroElPueblo.',
        place: 'Pachuca de Soto',
        description: '1702016927109902838?s=20',
        start: '2023-09-13',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Desde el presupuesto en el Gasto de Inversión 2023, fue una prioridad la infraestructura para el abasto de agua; con Juan Evel Chávez, titular de @CAASIM, realizamos el monitoreo de la obra “Pozo Colosio” para verificar la correcta aplicación de los recursos públicos, como lo ha instruido el gobernador @juliomenchaca_. #PrimeroElPueblo #RecursosDelPuebloYParaElPueblo',
        place: 'Pachuca de Soto',
        description: '1702101043184619984?s=20',
        start: '2023-09-13',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En @Hacienda_Hgo, desde el presupuesto, en el Gasto de Inversión 2023, priorizamos las obras y acciones que beneficien a las y los hidalguenses, tal como lo ha comprometido el gobernador @juliomenchaca_. En colaboración con el personal de @SIPDUS, llevamos a cabo el monitoreo de mantenimiento y construcción de infraestructura vial que se realiza con recursos del #Reemplacamiento2023 y #FAFEF, en los municipios de #Ajacuba y #MineralDeLaReforma, respectivamente.',
        place: 'Ajacuba y Mineral de la Reforma, Hidalgo',
        description: '1702156225347993737?s=20',
        start: '2023-09-13',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Reconozco a las y los servidores públicos que todos los días se suman con su esfuerzo a esta transformación para lograr #ElHidalgoQueQueremos. Acudí a los Centros Regionales de Hacienda de #Pachuca y #MineralDeLaReforma, donde reiteramos nuestro compromiso para dar a las personas un trato digno, cortés y cordial, como lo ha enfatizado el gobernador @juliomenchaca_.',
        place: 'Pachuca de Soto',
        description: '1702409368564617327?s=20',
        start: '2023-09-14',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Monitorear la obra pública para verificar que sea ejecutada conforme al avance financiero del Gasto de Inversión que reporta @Hacienda_Hgo, es importante para evaluar que los recursos públicos se apliquen de forma eficiente. Realizamos un recorrido con personal de la @SIPDUS en las obras de reconstrucción carretera, que se ejecuta con recursos #FAFEF, en los municipios de #Ajacuba y #SanAgustínTlaxiaca. #RecursosDelPuebloYParaELPueblo #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1702519134083260713?s=20',
        start: '2023-09-14',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En representación del gobernador @juliomenchaca_, acompañé al presidente de Eloxochitlán, Antonio Badillo García, en su 3er. Informe de Gobierno Municipal. El ejercicio de rendición de cuentas, abona a la democracia y transparencia, permite analizar los avances alcanzados, así como mantener claro los retos que representa el compromiso  que tenemos con la sociedad. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1702828680404230212?s=20',
        start: '2023-09-15',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Frente al gran Reloj Monumental de Pachuca, con mucho orgullo, miles de hidalguenses presenciamos junto a nuestro gobernador @juliomenchaca_, el Desfile Cívico Militar, del 213° Aniversario del Inicio de la Independencia de México. #VivaElPueblo',
        place: 'Pachuca de Soto',
        description: '1703155540522676304?s=20',
        start: '2023-09-16',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Saber qué hacer y cómo actuar en caso de emergencia, nos brinda la posibilidad de estar mejor preparados ante los eventuales siniestros, así como salvaguardar nuestra integridad y la de los demás. En Palacio de Gobierno, quienes laboramos en este edificio junto al gobernador @juliomenchaca_, nos sumamos al #SimulacroNacional; acompañados de las y los brigadistas, seguimos los protocolos establecidos por #ProtecciónCivil. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1704241265611600136?s=20',
        start: '2023-09-19',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Junto a Félix Adrián Brambila Mendoza, director general del Instituto Hidalguense de la Infraestructura Física Educativa, perteneciente a la @SEPHidalgo, damos seguimiento a los distintos proyectos de infraestructura educativa que han sido beneficiados con el presupuesto 2023, con la finalidad de revisar el cumplimiento de la aplicación de los recursos. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1704264924656972256?s=20',
        start: '2023-09-19',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En el marco de la 8ª. Sesión Ordinaria 2023 del Patronato del Sistema @difhgo, que encabeza la presidenta Edda Vite, fue gratificante entregar a nombre de la @Hacienda_Hgo la donación de más de un millón de pesos, derivado del proceso de destrucción y comercialización de las láminas, del programa de #Reemplacamiento2023. Como lo comprometió nuestro gobernador @juliomenchaca_ , el destino de los recursos debe llegar a donde más se necesita. Nuestro agradecimiento a las y los contribuyentes que con su canje de placas nos ayudaron aportar a esta noble causa. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1705051028750496094?s=20',
        start: '2023-09-21',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Fomentar la ciencia, el arte y la tecnología, desde las distintas actividades que ofrece el @museoelrehilete es importante para este gobierno; es por ello, que  platicamos con Jerusalem Kuri del Campo, directora general de dicho espacio, con el objetivo de realizar la revisión de proyectos para fortalecerlo y así seguir brindando a las familias hidalguenses espacios que promuevan la creatividad y el aprendizaje.',
        place: 'Pachuca de Soto',
        description: '1705421719769362720?s=20',
        start: '2023-09-22',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Es un gusto haber participado en la reunión interinstitucional que encabezó el gobernador @juliomenchaca_ entre el @gobiernohidalgo y el @GobiernoMx. Estas mesas de trabajo son fundamentales para fortalecer con las delegaciones federales la cooperación, y continuar sumando esfuerzos en beneficio de las y los hidalguenses.',
        place: 'Pachuca de Soto',
        description: '1706443231779590274?s=20',
        start: '2023-09-25',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Junto al secretario de @PlaneacionHgo, @mtellov y titulares de los organismos descentralizados  @coemerehgo, @_DESCTI, @CITNOVA y @cedspi revisamos los proyectos prioritarios del Gasto de Inversión 2024. La transformación de #Hidalgo nada la detiene; las acciones de este gobierno están encaminadas a cumplir con objetivos del Plan Estatal de Desarrollo para el bienestar de las familias hidalguenses. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1706831513797435493?s=20',
        start: '2023-09-26',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Trabajamos en estrategias para fortalecer la política laboral y mejores condiciones para las y los trabajadores; con el secretario de @strabajohgo , @OscarJavierGnz2 y la directora de @ConciliacionHGO, @Mariela_ValeroM, revisamos los distintos proyectos encaminados al ejercicio 2024. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1707196101407961451?s=20',
        start: '2023-09-27',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En la CCCLIII Reunión de la Comisión Permanente de Funcionarios Fiscales, que tiene lugar en #Oaxaca, compartimos experiencias y conocimientos para fortalecer las haciendas públicas. Esto como parte de los diversos ejes temáticos de nuestra agenda compartida. #PrimeroElPueblo',
        place: 'Oaxaca',
        description: '1707841005041357120?s=20',
        start: '2023-09-29',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Con avances positivos, se realizó la 3.ª Reunión Ordinaria del Consejo de Armonización Contable del Estado de Hidalgo #CACEH, en la cual,  informé que la entidad a nivel nacional se colocó en los primeros lugares de cumplimiento de información financiera en la Primera Evaluación 2023, del Sistema de Evaluaciones de la Armonización Contable #SEVAC, de @Hacienda_Mexico.',
        place: 'Pachuca de Soto',
        description: '1708984936886157655?s=20',
        start: '2023-10-02',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Con los titulares de las subsecretarías de @Hacienda_Hgo, llevamos a cabo las reuniones de cierre del ejercicio fiscal 2023; el cual, será con finanzas sanas debido a un manejo eficiente de los recursos y enfocado a incidir positivamente en el bienestar de la población. Bajo el liderazgo del gobernador @juliomenchaca_ el @gobiernohidalgo mantiene una disciplina financiera.',
        place: 'Pachuca de Soto',
        description: '1709343175641563137?s=20',
        start: '2023-10-03',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En nuestra primera reunión de gabinete del segundo año de esta administración, evaluamos y dimos seguimiento a los proyectos que se están realizando así como los próximos a llevar a cabo en beneficio de la ciudadanía. Para el gobernador @juliomenchaca_ y el @gobiernohidalgo es #PrimeroElPueblo.',
        place: 'Pachuca de Soto',
        description: '1709735191508390004?s=20',
        start: '2023-10-04',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En reunión de trabajo con Orlando Ángeles Pérez, titular de @OficialiaMa, revisamos los proyectos de presupuesto para el cierre del ejercicio 2023, así como los que se habrán de llevar a cabo en 2024. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1710165079654584821?s=20',
        start: '2023-10-05',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Con un presupuesto enfocado en las necesidades más sentidas de la población, estamos construyendo #ElHidalgoQueQueremos. Es un honor haber acompañado al gobernador @juliomenchaca_ en el anuncio de inversión de 105 millones destinados a la construcción, ampliación y rehabilitación de obras hidráulicas, para el abastecimiento de agua.',
        place: 'Pachuca de Soto',
        description: '1710426384307281970?s=20',
        start: '2023-10-06',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Estamos rompiendo las viejas prácticas, por primera vez enfocamos los recursos a las necesidades más sentidas del pueblo. #ResultadosQueTransforman. #1eraComparecenciaHidalgo #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1711811942224892216?s=20',
        start: '2023-10-10',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Fue un gusto acompañar al gobernador @juliomenchaca_ en la apertura de la exposición "Kénosis, intervención gráfica 1.2”. En esta administración reconocemos e impulsamos la cultura de las y los artistas hidalguenses. Les invito a disfrutar de estas obras cuando visiten #PalacioDeGobierno.',
        place: 'Pachuca de Soto',
        description: '1712265044103192645?s=20',
        start: '2023-10-11',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Con @mtellov, titular de @PlaneacionHgo y Francisco Quijas Cruz, subsecretario de Protección Civil y Gestión de Riegos, revisamos los proyectos de prioridad para el ejercicio 2024, a fin de garantizar una responsable atención a la  ciudadanía en temas de prevención y riegos.',
        place: 'Pachuca de Soto',
        description: '1712294176195215676?s=20',
        start: '2023-10-11',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En reunión con el titular del @Indemun, Abel Cerón San Nicolás y su equipo de trabajo, analizamos los proyectos prioritarios para el ejercicio 2024, que tienen el objetivo de fortalecer las capacidades institucionales de las autoridades de los 84 municipios.',
        place: 'Pachuca de Soto',
        description: '1712598164975346138?s=20',
        start: '2023-10-12',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Fue un honor acompañar al gobernador @juliomenchaca_en la firma del Convenio para la Universalidad de la Pensión para el Bienestar de las Personas con Discapacidad entre la Secretaría de @bienestarmx y el  @gobiernohidalgo. Con este proyecto estamos eliminando barreras y otorgándole a este sector vulnerable, las herramientas necesarias para su autonomía y desarrollo.',
        place: 'Pachuca de Soto',
        description: '1714066391764967813?s=20',
        start: '2023-10-16',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Llevé a cabo una reunión de trabajo con el personal de las subsecretarías de @Hacienda_Hgo, donde analizamos los diversos proyectos del presupuesto de egresos para el año 2024. En la reunión, enfatizamos la importancia de que la aplicación de los recursos públicos sea eficiente, transparente y honesta.',
        place: 'Pachuca de Soto',
        description: '1742349700336898169?s=20',
        start: '2024-01-02',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Visité el área de Auditoría Fiscal perteneciente a la Subsecretaría de Ingresos, donde tuve el gusto de saludar al personal que se encuentra trabajando, a quienes reconozco por el compromiso y responsabilidad para iniciar el 2024.',
        place: 'Pachuca de Soto',
        description: '1743444938308637074?s=20',
        start: '2024-01-05',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Junto a nuestro gobernador @juliomenchaca_, expusimos los temas de agenda de la primera reunión de gabinete 2024; desde nuestras respectivas responsabilidades, refrendamos nuestro compromiso para seguir impulsando la transformación de Hidalgo. ¡Éxito a quienes emprenden nuevos proyectos!',
        place: 'Pachuca de Soto',
        description: '1744696274941509930?s=20',
        start: '2024-01-08',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'La educación es fundamental en la administración de nuestro gobernador @juliomenchaca_. En el ejercicio 2024, se destinaron más de 29 mil mdp en programas que abordan temas como becas, útiles escolares y uniformes. Fue un placer acompañarlo en el evento de Restitución de Daños a Planteles Educativos por Siniestros causados por el desbordamiento del Río #Tula en 2021. Se entregó mobiliario y equipo esencial para el desarrollo educativo de nuestras niñas y niños. #ConEducaciónHayTransformación',
        place: 'Tula de Allende, Hidalgo',
        description: '1744880633875144852?s=20',
        start: '2024-01-09',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Para dar seguimiento y organizar los temas a trabajar en colaboración con la federación, en mi calidad de Coordinadora de la Comisión Permanente de Funcionarios Fiscales, sostuve una reunión virtual con Alan Carbajal Zúñiga, director general de Transferencias Federales de la Unidad de Coordinación con Entidades Federativas de la @Hacienda_Mexico,  junto con funcionarias del estado de Querétaro.',
        place: 'Pachuca de Soto',
        description: '1745264614407868706?s=20',
        start: '2024-01-10',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En mi calidad de Coordinadora de la Comisión Permanente de Funcionarios Fiscales, me reuní con el Mtro. Fernando Renoir Baca Rivera, titular de la Unidad de Coordinación con Entidades Federativas en la @Hacienda_Mexico, con el propósito de abordar los temas relacionados al programa de trabajo que desarrollaremos durante el periodo 2023-2024; con ello, daremos continuidad a las acciones para el desarrollo del Sistema Nacional de Coordinación Fiscal #SNCF.',
        place: 'Pachuca de Soto',
        description: '1745550983076200861?s=20',
        start: '2024-01-11',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Junto a @_AlejandroSanG, titular de la @SIPDUS y Ernesto Jauregui Asomoza, delegado de la @SCT_Hidalgo, nos reunimos para trabajar de forma coordinada en los proyectos prioritarios de infraestructura en todo el estado; los cuales son cruciales para continuar con la transformación de #ElHidalgoQueQueremos.',
        place: 'Pachuca de Soto',
        description: '1747133682559644156?s=20',
        start: '2024-01-15',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Es un orgullo ser hidalguense! Junto al gobernador @juliomenchaca_ celebramos la grandeza de nuestro estado con motivo del 155º Aniversario de la Erección del Estado de Hidalgo. #Hidalgo155Años #LoQueAmoDeHidalgo #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1747339976851767686?s=20',
        start: '2024-01-16',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En unidad continuamos trabajando por la transformación de #ElHidalgoQueMerecemos. Con el encargado del despacho de la @PGJE_Hidalgo, Francisco Hernández Hasbun, platiqué sobre el seguimiento de la transición a lo que será la próxima Fiscalía General de Justicia del Estado de Hidalgo, analizamos los recursos y proyectos considerados para garantizar una procuración de justicia eficaz, efectiva y apegada a derecho.',
        place: 'Pachuca de Soto',
        description: '1747379552328863793?s=20',
        start: '2024-01-16',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Con @MemoOlivares_, titular de la @SEGOBHidalgo y @ZoraydaRoblesB, titular de @Salud_Hidalgo, acudimos a Palacio Nacional para participar en las mesas de trabajo para la revisión del convenio de Federalización IMSS-Bienestar, con ello se estará fortaleciendo un sistema de salud más accesible para las familias hidalguenses.',
        place: 'Pachuca de Soto',
        description: '1748121719234007463?s=20',
        start: '2024-01-18',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Trabajar de forma coordinada para atender la obligación constitucional de derecho a la vivienda, es un compromiso que resaltó nuestro gobernador @juliomenchaca_, durante la entrega de 250 escrituras, en la cual estuvo presente José Alfonso Iracheta Carroll, director general de @INSUS_Oficial. Fue un gusto ser testigo de esta acción que significa otorgar certeza jurídica del patrimonio de familias hidalguenses de distintos municipios. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1749569599689888083?s=20',
        start: '2024-01-22',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En seguimiento y cumplimiento oportuno del gasto de inversión, nos reunimos con distintas áreas ejecutoras con el objetivo de conocer los avances en los diferentes proyectos estratégicos.',
        place: 'Pachuca de Soto',
        description: '1749988949152465056?s=20',
        start: '2024-01-23',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En conjunto con @SEGOBHidalgo, @SSP_Hidalgo, @OficialiaMa, @PGJE_Hidalgo y el @Srio_CespHgo, participamos en la reunión virtual de coordinación de proyectos federales en materia de seguridad, para las entidades federativas en el ejercicio 2024, que encabezó la @SSPCMexico a través del @sesnsp.',
        place: 'Pachuca de Soto',
        description: '1750657499856875944?s=20',
        start: '2024-01-25',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Con la presencia del gobernador @juliomenchaca_ y en mi carácter de coordinadora de la Comisión Permanente de Funcionarios Fiscales, llevamos a cabo la CCCLVI reunión, en la cual presenté el plan de trabajo que tiene como objetivo fortalecer los ingresos de los estados para mejorar las haciendas públicas y continuar con la transformación de Hidalgo y México.',
        place: 'Pachuca de Soto',
        description: '1751021205019062761?s=20',
        start: '2024-01-26',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En reunión con el titular de @Sedeco_Hgo, @carekad analizamos los proyectos e inversiones clave para el 2024. La encomienda del gobernador @juliomenchaca_ es clara: impulsar el desarrollo económico de nuestro estado, generando nuevas y mejores oportunidades para las y los hidalguenses',
        place: 'Pachuca de Soto',
        description: '1752851943037309341?s=20',
        start: '2024-01-31',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Me reuní con el director de @CEAA_HGO, Juan Carlos Chávez González, analizamos y damos seguimiento a los distintos proyectos hídricos, existe una inversión pública importante para este rubro con el objetivo de que todas las familias hidalguenses tengan acceso al vital líquido.',
        place: 'Pachuca de Soto',
        description: '1753238913693450305?s=20',
        start: '2024-02-01',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Junto a @MemoOlivares_, titular de @SEGOBHidalgo y @ZoraydaRoblesB, titular de @Salud_Hidalgo, así como las y los demás integrantes del Consejo Estatal de Salud del Estado de Hidalgo, participamos en la sesión extraordinaria para colaborar de manera intersectorial en las acciones de protección a la salud de las familias hidalguenses. #PrimeroLaSalud #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1753528933352628583?s=20',
        start: '2024-02-02',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En unidad trabajamos por el bienestar de #Hidalgo. En reunión junto al gobernador @juliomenchaca_ y los titulares de las secretarías que integran el @gobiernohidalgo dimos seguimiento a los proyectos de este 2024, con el objetivo de seguir continuando con la transformación de nuestro estado.',
        place: 'Pachuca de Soto',
        description: '1754674273900208456?s=20',
        start: '2024-02-05',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '#Hidalgo es un estado rico en cultura; con @taniameza_, titular de @CulturaHidalgo trabajamos en los proyectos que serán  prioritarios para el ejercicio fiscal 2024. En la administración del gobernador @juliomenchaca_ se destinan importantes recursos para este sector esencial en la cohesión social, turismo y crecimiento económico. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1754988608044314701?s=20',
        start: '2024-02-06',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Aplicar los recursos públicos de manera eficiente para  el bienestar de las familias hidalguenses, es una prioridad para la administración del gobernador @juliomenchaca_. Junto con @AlvaroBardales2, titular de @Contraloria_Hgo, nos reunimos con @_AlejandroSanG, titular de @SIPDUS, para realizar un monitoreo de las distintas obras públicas. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1756129471017386335',
        start: '2024-02-09',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En colaboración con la @Contraloria_Hgo, nos reunimos con Félix Adrián Brambila Mendoza, director general del Instituto Hidalguense de la Infraestructura Física Educativa, para realizar la revisión del gasto de inversión. El objetivo es garantizar la correcta ejecución de los proyectos y maximizar su impacto en el sector educativo. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1756141432920743994',
        start: '2024-02-09',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En conjunto con la @Contraloria_Hgo, seguimos el análisis para asegurar la transparencia en la ejecución de proyectos hídricos y garantizar que los recursos reviertan en la sociedad. Con Juan Evel Chávez Trovamala, titular de  la @CAASIM y Juan Carlos Chávez González, director de @CEAA_HGO , sostuvimos reuniones para revisar los fondos asignados por el gobierno destinados a la construcción y mejora de obras, buscando asegurar el acceso al agua para todas y todos. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1756377207528636627',
        start: '2024-02-10',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Compartir experiencias de mejora entre las y los responsables de la operación de las haciendas públicas es uno de los objetivos fundamentales del 2do. Foro de la Red de Administradores Financieros Públicos, en el cual @Hacienda_Hgo está participando. En mi calidad de coordinadora de la Comisión Permanente de Funcionarios Fiscales, es un placer formar parte de esta mesa de trabajo que cuenta con la colaboración de expertos tanto nacionales como internacionales, y que se está llevando a cabo en Campeche.',
        place: 'Campeche',
        description: '1758552194435969235',
        start: '2024-02-16',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'El trabajo coordinado entre la federación y las entidades es indispensable para la mejora de las haciendas públicas. Durante el 2do. Foro de la Red de Administradores Financieros Públicos fue un gusto coincidir con Gabriel Yorio González, subsecretario de @Hacienda_Mexico ; @nadgasman, titular de @inmujeres; Fernando Renoir Baca Rivera, titular de la #UCEF y Carlos García Lepe, director general del @INDETEC_mx, quienes también comparten la importancia de realizar estos foros que nos permiten desarrollar estrategias que impulsan y fortalecen la eficiente aplicación de los recursos públicos para el bienestar del pueblo.',
        place: 'Campeche',
        description: '1758665930932969568',
        start: '2024-02-16',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Desde nuestras respectivas responsabilidades trabajamos por un #HidalgoConBienestar. Un gusto acompañar al gobernador @juliomenchaca_ en el “Arranque de la Entrega  de Programas para el Bienestar”, cada uno será  crucial para mejorar las condiciones de calidad de vida de las familias hidalguenses. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1760510057886662847',
        start: '2024-02-21',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En la Segunda Reunión Virtual del Comité de Vigilancia de Aportaciones y Otras Transferencias Federales Etiquetadas, dimos seguimiento a la distribución de los fondos de aportaciones federales que correspondan a los estados. En mi carácter de coordinadora de la Comisión Permanente de Funcionarios Fiscales y de los estados integrantes del Comité mencionado, formalizamos acuerdos que permitirán mejores prácticas de administración y ejercicio de dichos recursos.',
        place: 'Pachuca de Soto',
        description: '1760786209394098242',
        start: '2024-02-22',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Con la optimización y correcta aplicación de los recursos públicos se construye #ElHidalgoQueQueremos. Es un honor acompañar al gobernador @juliomenchaca_ en la entrega de 54 máquinas que contribuirán al fortalecimiento de la obra pública del estado. El objetivo del @gobiernohidalgo es claro, continuar formando alianzas estratégicas para transformar la entidad. #PrimeroElPueblo',
        place: 'Mineral de la Reforma',
        description: '1761149886764859657',
        start: '2024-02-23',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En el marco de los trabajos que se realizan dentro de la Comisión Permanente de Funcionarios Fiscales, la cual preside su servidora, participé en la Segunda Reunión Ordinaria del Grupo de Auditoría Fiscal Federal 2024, la cual encabeza José de Jesús Granillo Vázquez, Secretario de Hacienda del Poder Ejecutivo del Estado de Chihuahua. Con las entidades integrantes, así como funcionarios de @Hacienda_Mexico y del @INDETEC_mx, dimos seguimiento a los temas de agenda nacional respecto al cumplimiento de las obligaciones fiscales.',
        place: 'Pachuca de Soto',
        description: '1762582651121041875',
        start: '2024-02-27',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En compañía de las subsecretarías de Ingresos, Egresos y Programación y Presupuesto del Gasto de Inversión de @Hacienda_Hgo, realizamos una reunión con el objetivo de prepararnos para las mesas de trabajo que sostendremos con agencias calificadoras en los próximos meses.',
        place: 'Pachuca de Soto',
        description: '1763026339752194279',
        start: '2024-02-28',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Acompañada de Fernando Renoir Baca Rivera, titular de la UCEF de @Hacienda_Mexico; Carlos García Lepe, Director General del @INDETEC_mx y Alan Carbajal Zúñiga, Director General de Transferencias Federales de la #UCEF, iniciamos la mesa de trabajo previa a la CCCLVII Reunión de la Comisión Permanente de Funcionarios Fiscales, en Querétaro. En mi calidad de coordinadora de esta Comisión, dimos seguimiento a los temas de agenda nacional que encabezan los distintos grupos del Sistema Nacional de Coordinación Fiscal, lo cual permitirá generar acciones concretas para el fortalecimiento de las haciendas públicas estatales y municipales.',
        place: 'Querétaro',
        description: '1763390188149998071',
        start: '2024-02-29',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Un gusto participar en el III Foro de Procuradores Fiscales, en Querétaro, el cual fue presidido por la procuradora Fiscal de la Federación, Grisel Galeano García, quien destacó la importancia de esta labor en los procedimientos o procesos judiciales y administrativos que realizan las secretarías de finanzas, hacienda y administración pública.',
        place: 'Querétaro',
        description: '1763406520186790237',
        start: '2024-02-29',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Sostuvimos una reunión de trabajo con la agencia calificadora @HRRATINGS.',
        place: 'Pachuca de Soto',
        description: '1764796642547433499',
        start: '2024-03-04',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Con la @Contraloria_Hgo, @Salud_Hidalgo y @SIPDUS, sostuvimos una mesa de trabajo para dar seguimiento y atender las solicitudes de la @ASEH_Hgo y @ASF_Mexico.',
        place: 'Pachuca de Soto',
        description: '1767335498282832036',
        start: '2024-03-11',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Para atender de forma oportuna y coordinada las solicitudes de los entes fiscalizadores estatal y federal, con la @Contraloria_Hgo realizamos una mesa de trabajo con el @CongresoHidalgo y el @IEEHidalgo.',
        place: 'Pachuca de Soto',
        description: '1767349233844212102',
        start: '2024-03-11',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Llevamos a cabo una reunión con @Sedeco_Hgo y @OficialiaMa con el objetivo de trabajar la información que será presentada a la agencia crediticia internacional S&P Global Ratings, para la evaluación del @gobiernohidalgo en los próximos días.',
        place: 'Pachuca de Soto',
        description: '1771282519838900599',
        start: '2024-03-22',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En conjunto con @Sedeco_Hgo y @OficialiaMa, llevamos a cabo la reunión anual con la agencia internacional S&P Global Ratings.',
        place: 'Pachuca de Soto',
        description: '1772782691751989382',
        start: '2024-03-26',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Nos reunimos con el titular del Poder Ejecutivo para dar seguimiento a las acciones que realizamos en conjunto con mis compañeros de gabinete.',
        place: 'Pachuca de Soto',
        description: '1775997893352042767',
        start: '2024-04-04',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Para dar continuidad a las acciones en la transición del sistema de salud a IMSS-Bienestar, nos reunimos con autoridades federales.',
        place: 'Pachuca de Soto',
        description: '1776400280701038600',
        start: '2024-04-05',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Hidalgo presente en el 2do. Encuentro de Titulares de las Secretarías de Finanzas, en el marco del Modelo Hacendario Estatal para la Cohesión Social.',
        place: 'Quintana Roo',
        description: '1778508469567435256',
        start: '2024-04-11',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En este 2do. Encuentro de Titulares de las Secretarías de Finanzas, en el marco del Modelo Hacendario Estatal para la Cohesión Social, es un gusto participar con mis homólogas y homólogos del país, con la @Hacienda_Mexico y la Delegación de la Unión Europea en México.',
        place: 'Pachuca de Soto',
        description: '1778611659952525616',
        start: '2024-04-11',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'El fortalecimiento de los ingresos, la calidad del gasto, la transparencia y la rendición de cuentas, han sido los objetivos substanciales en los que hemos avanzado en este encuentro del Modelo Hacendario Estatal para la Cohesión Social, realizado en Quintana Roo, donde participamos 25 entidades. Bajo un trabajo coordinado entre los estados, el Gobierno Federal y la Delegación de la Unión Europea en México nos sumamos a los esfuerzos para el diseño de presupuestos alineados con los Objetivos de Desarrollo Sostenible.',
        place: 'Quintana Roo',
        description: '1778935502663450807',
        start: '2024-04-12',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Fue un gusto disfrutar esta noche la pasión por el fútbol, con el equipo femenil de la Escuela Primaria “Profesor Malaquías Piña”, quienes nos acompañaron en el palco del pueblo para el partido entre de los @Tuzos contra el @MazatlanFC.',
        place: 'Pachuca de Soto',
        description: '1784452487841226758',
        start: '2024-04-27',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En representación del titular del @gobiernohidalgo, asistí ante las y los integrantes de la LXV Legislatura del @CongresoHidalgo, a efecto de cumplir en tiempo y forma con la entrega de la Cuenta de la Hacienda Pública Estatal Integrada y Consolidada, correspondiente al ejercicio fiscal 2023.',
        place: 'Pachuca de Soto',
        description: '1785084720163811803s',
        start: '2024-04-29',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '¡La emoción se desbordó en el partido @Tuzos vs @PumasMX ! Junto al equipo varonil de fútbol de la Escuela Primaria "Profr. Malaquías Piña", vivimos una noche llena de pasión por este deporte. Un juego sin duda inolvidable ⚽',
        place: 'Pachuca de Soto',
        description: '1786285238613614989',
        start: '2024-05-03',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Con el objetivo de continuar trabajando en las necesidades del pueblo llevamos a cabo la reunión de gabinete en la que abordamos importantes temas de las distintas secretarías que forman parte del @gobiernohidalgo.',
        place: 'Pachuca de Soto',
        description: '1786631737059008786',
        start: '2024-05-03',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Sostuvimos una reunión fructífera con el Grupo Financiero Santander y Santander Tuiio, la cual será crucial para las  acciones que impulsen el crecimiento y desarrollo de las MiPyMEs en Hidalgo.',
        place: 'Pachuca de Soto',
        description: '1787994508128297314',
        start: '2024-05-07',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En @Hacienda_Hgo, se llevó a acabo el acto de formalización para dar inicio a los trabajos de auditoría de la Cuenta Pública 2023; la transparencia y rendición de cuentas sobre el ejercicio de los recursos públicos son trascendentales para que la ciudadanía conozca el destino y buen uso de sus impuestos.',
        place: 'Pachuca de Soto',
        description: '1788305313424179228',
        start: '2024-05-08',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En la CCCLVIII Reunión de la Coordinación Permanente de Funcionarios Fiscales, realizada en Guadalajara, Jalisco, fue un gusto que nos acompañara David Rogelio Colmenares, Auditor Superior de la Federación.',
        place: 'Guadalajara',
        description: '1791590230866165939',
        start: '2024-05-17',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Felicitaciones a todo el gran equipo del INDETEC, por la colocación de la primera piedra de sus nuevas instalaciones. Representa un acto de esfuerzo y compromiso; sin duda este espacio seguirá contribuyendo a la profesionalización de las haciendas públicas del país.',
        place: 'Guadalajara',
        description: '1791664018454687757',
        start: '2024-05-17',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Se llevó a cabo la revisión anual con la agencia calificadora #FitchRatings, con el objetivo de que nuestra entidad sea evaluada. En conjunto con @Sedeco_Hgo, @OficialiaMa y @CAASIM, presentamos información correspondiente a la política fiscal implementada para que los recursos públicos del estado sean aplicados de manera eficiente.',
        place: 'Pachuca de Soto',
        description: '1793057682544287830',
        start: '2024-05-21',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En Durango, participamos en el primer Foro de Movilización de Financiamiento Sostenible, el cual tiene el objetivo de brindar herramientas de planeación y gestión con enfoque sostenible para el fortalecimiento de acciones coordinadas y soluciones financieras innovadoras que abonen a las políticas de desarrollo de los Estados y ciudades. Agradecemos al Gobierno del Estado de Durango, quien en colaboración con la Unidad de Coordinación con Entidades Federativas de la @Hacienda_Mexico, el Banco Interamericano de Desarrollo, el INDETEC y el Banco Nacional de Obras y Servicios Públicos  realizan este evento.',
        place: 'Durango',
        description: '1794132333974454611',
        start: '2024-05-24',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'El día de hoy llevamos a cabo una reunión junto con @SIPDUS, @Salud_Hidalgo, @Contraloria_Hgo, @PlaneacionHgo e IMSS Bienestar Delegación Hidalgo, donde dimos seguimiento a los proyectos en los que estamos trabajando conjuntamente en la entidad. Esto nos permite revisar los avances enfocados a mejorar la infraestructura y los servicios de salud.',
        place: 'Pachuca de Soto',
        description: '1796010665208947104',
        start: '2024-05-29',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'El bienestar del pueblo es nuestra prioridad. ',
        place: 'Pachuca de Soto',
        description: '1798529544065818679',
        start: '2024-06-05',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '🤝Con las diferentes áreas de  @Hacienda_Hgo, iniciamos los trabajos para la integración del próximo #PaqueteHacendario2025 💰. Bajo el liderazgo de nuestro gobernador @juliomenchaca_  la transformación de #Hidalgo continúa ⚙️, en este proyecto seguiremos consolidando las prioridades y objetivos para impulsar el bienestar del pueblo hidalguense. #ElHidalgoQueQueremos',
        place: 'Pachuca de Soto',
        description: '1801786934852764122',
        start: '2024-06-14',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '🤝 Con el objetivo de compartir experiencias e información que impulsen acciones para el fortalecimiento hacendario💰, esta mañana nos reunimos con Antonio García Carreño, titular de la Unidad de Financiamiento y Asistencia Técnica de @Banobras_mx #VPIE. ✅ Formalizamos el inicio de un proyecto coordinado, para incentivar la inscripción de los contribuyentes al Impuesto sobre Nómina y facilitar el cumplimiento de las obligaciones fiscales, las cuales nos permiten proveer de recursos públicos para continuar con la transformación de #ElHidalgoQueQueremos #VPIE ⚙️.',
        place: 'Pachuca de Soto',
        description: '1802846413211996661',
        start: '2024-06-17',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Con el objetivo mantener la estabilidad operativa y administrativa de los gobiernos municipales entrantes y el @gobiernohidalgo; en conjunto con @ASEH_Hgo y @Contraloria_Hgo trabajamos en temas relevantes que abordaremos en las próximas capacitaciones que estaremos desarrollando con los municipios para asegurar que los esfuerzos y recursos se sumen a la transformación de #ElHidalgoQueQueremos.',
        place: 'Pachuca de Soto',
        description: '1803231813369995750',
        start: '2024-06-18',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Damos seguimiento a los proyectos prioritarios de infraestructura en la entidad, con los secretarios @_AlejandroSanG, de @SIPDUS y @AlvaroBardales2, de @Contraloria_Hgo, revisamos los avances para evaluar que sus procesos sean según lo programado y dentro del presupuesto correspondiente. Con más y mejor infraestructura sigue la transformación de #Hidalgo. 🚧👷🏻‍♂️',
        place: 'Pachuca de Soto',
        description: '1803283578572136933',
        start: '2024-06-18',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En el marco de la Cuarta Sesión del Patronato 2024, presidida por Edda Vite, presidenta del @difhgo, revisamos las diferentes actividades con causa que estaremos realizando en favor de los grupos prioritarios. #ElHidalgoQueQueremos #YoSoyFamiliaDIFH',
        place: 'Pachuca de Soto',
        description: '1803995125628178490',
        start: '2024-06-20',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Con @SEGOBHidalgo, @OficialiaMa, @Sedeco_Hgo, @medioambiente_H y @CAASIM nos preparamos para próxima mesa de trabajo que habremos de tener con la agencia internacional @moodysratings. 📈',
        place: 'Pachuca de Soto',
        description: '1804304946830946798',
        start: '2024-06-21',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Esta tarde atendimos la reunión anual de evaluación con los analistas de la agencia internacional @moodysratings; acompañada de la @Sedeco_Hgo, @medioambiente_H, @SEGOBHidalgo, @OficialiaMa y @CAASIM en la que presentamos las diferentes acciones que han abonado a la política financiera para que el desarrollo integral de #Hidalgo esté consolidado.',
        place: 'Pachuca de Soto',
        description: '1805740221306397004',
        start: '2024-06-25',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Impulsar el talento y las actividades deportivas siempre será una herramienta importante para incidir en el bienestar social, es por ello que damos seguimiento a los proyectos en dicha materia con Oscar Pérez Rojas, titular del @deportehidalgo. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1806067539094425677',
        start: '2024-06-26',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En la  2.ª Sesión Ordinaria del Consejo de Armonización Contable del Estado de Hidalgo #CACEH, se tomó protesta a @VaneEscalanteA, titular de @Salud_Hidalgo y se presentó el Informe de Resultados de la Cuenta de la Hacienda Pública del Estado de Hidalgo, del ejercicio 2023. 💰En esta sesión también se presentaron las Evaluaciones de la Armonización Contable #SEvAC a los entes públicos de estado, donde reiteramos el compromiso de trabajar información homogénea de las finanzas, facilitando el escrutinio público y las tareas de fiscalización. Estuvieron presentes @jorvalverdeASEH, de la @ASEH_Hgo y  @OsirisLeines, presidente de la Junta de Gobierno del @CongresoHidalgo',
        place: 'Pachuca de Soto',
        description: '1806118075026034959',
        start: '2024-06-26',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Este lunes dimos inicio a las “Jornadas de Capacitación para las Administraciones Municipales Entrantes del Estado de Hidalgo”, en coordinación con @AlvaroBardales2, de @Contraloria_Hgo y @jorvalverdeASEH, de la @ASEH_Hgo. Durante tres días trabajaremos con los 84 municipios a fin de fortalecer las capacidades institucionales de las y los servidores públicos en temas de presupuesto, aplicación correcta de la normativa y auditoría de los recursos. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1807888692872204455',
        start: '2024-07-01',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En reunión con @VaneEscalanteA, titular de @Salud_Hidalgo y @Felipe_ArreolaT, coordinador estatal del @IMSS_BIENESTAR en #Hidalgo, revisamos los avances de los trabajos de transición del sistema de salud a la implementación del modelo #MASBIENESTAR. 🩺🙋🏻‍♀️🙋🏻‍♂️',
        place: 'Pachuca de Soto',
        description: '1807978475833176399',
        start: '2024-07-01',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '🤝 Realizamos la última capacitación en materia hacendaria de los 16 municipios, considerados para la región Pachuca, con  las Subsecretarías de Ingresos, Programación y Presupuesto del Gasto de Inversión, Egresos y el @CatastroHidalgo. A las y los alcaldes electos presentamos los temas prioritarios para administrar eficientemente y lograr finanzas sanas; estos conocimientos son herramientas que les permitirán cumplir con sus obligaciones y brindar servicios de calidad que impulsen la transformación de #Hidalgo.',
        place: 'Pachuca de Soto',
        description: '1808639228974412131',
        start: '2024-07-03',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Los esfuerzos conjuntos entre las Secretarías del @gobiernohidalgo es clave para continuar transformando a #Hidalgo. Con nuestro gobernador @juliomenchaca_ #PrimeroElPueblo.',
        place: 'Pachuca de Soto',
        description: '1808639228974412131',
        start: '2024-07-05',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Las acciones de previsión, prevención, protección y rehabilitación de la asistencia social son permanentes en #Hidalgo; por ello, junto a Ricardo Enrique Alvizo Contreras, director del @difhgo, trabajamos en la administración de recursos que garanticen la protección de las personas en situación de vulnerabilidad, especialmente de infantes y adolescentes. #PrimeroElPueblo #LaTransformaciónSigueEnHidalgo',
        place: 'Pachuca de Soto',
        description: '1810509409291756014',
        start: '2024-07-08',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Continuamos con la transformación de #Hidalgo desde todos los aspectos que permitan el desarrollo integral de nuestro estado; para impulsar acciones que nos permitan brindar programas de vivienda digna para las familias, nos reunimos con Jorge Arturo Castañeda Montes de Oca, titular de @Cevi_Hidalgo.',
        place: 'Pachuca de Soto',
        description: '1811164579461832718',
        start: '2024-07-10',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Esta mañana inauguramos el “Programa de Capacitación y Profesionalización en materia de Armonización Contable 2024”, donde estarán participando los poderes, órganos autónomos, organismos descentralizados y administraciones municipales.',
        place: 'Pachuca de Soto',
        description: '1812912524993376504',
        start: '2024-07-15',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Para revisar la aplicación correcta de los recursos públicos en 30 de los municipios de #Hidalgo, el titular de la @ASF_Mexico, David Rogelio Colmenares y el gobernador @juliomenchaca_ inauguraron la “Apertura de Auditorías Colmena 2024”. ',
        place: 'Pachuca de Soto',
        description: '1813699949907096059',
        start: '2024-07-17',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En el marco de la Quinta Sesión Ordinaria del Consejo del @difhgo, la cual encabezó Edda Vite, Presidenta del Patronato del Sistema, revisamos las distintas acciones en favor de los grupos prioritarios del estado. #ElHidalgoQueQueremos #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1814123252207292755',
        start: '2024-07-18',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Fue un gusto acompañar a la presidenta del @difhgo, Edda Vite Ramos, al gobernador @juliomenchaca_ y al oficial mayor, Orlando Ángeles Pérez, en la inauguración del Curso de Verano 2024, un espacio donde las infancias podrán divertirse mientras aprenden.',
        place: 'Pachuca de Soto',
        description: '1815979151326662880',
        start: '2024-07-23',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Agradezco al gobernador @juliomenchaca_ quien esta mañana nos reconoció como la primera generación de funcionarias y funcionarios públicos en concluir el programa de la Academia de la Transformación. Gracias a esta iniciativa, podemos continuar mejorando nuestras habilidades y conocimientos para servir mejor al pueblo de #Hidalgo. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1816957010245771440',
        start: '2024-07-26',
        color: 'var(--wine)'
    },
    { 
        id: 0,
        title: '📅 Sostuvimos la #ReuniónMensualDeGabinete junto al gobernador @juliomenchaca_ y quienes integramos el equipo del @gobiernohidalgo, para trabajar en temas de relevancia que aporten al crecimiento y desarrollo de #Hidalgo, en busca del bienestar de la ciudadanía.  🙋🏻‍♂️🙋🏻‍♀️',
        place: 'Pachuca de Soto',
        description: '1820578190059798979',
        start: '2024-08-05',
        color: 'var(--wine)'
    },
    { 
        id: 0,
        title: 'Sostuvimos una mesa de trabajo con integrantes de @Salud_Hidalgo, donde revisamos cuotas y tarifas de los conceptos que seguirán a cargo de Servicios de Salud del Estado, con la finalidad de coadyuvar al acceso a un sistema de salud digno, humano y de calidad para el pueblo de #Hidalgo.',
        place: 'Pachuca de Soto',
        description: '1820983747954798876',
        start: '2024-08-06',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Me reuní con el titular de  @PlaneacionHgo,  @mtellov, para revisar los lineamientos requeridos para los programas y proyectos de inversión que serán parte del  #PaqueteHacendario2025, su integración nos permite garantizar que los recursos públicos tengan un beneficio social.  ',
        place: 'Pachuca de Soto',
        description: '1824162484422357073',
        start: '2024-08-15',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '✅ Hidalgo presidió la CCCLIX Reunión de la Comisión Permanente de Funcionarios Fiscales, en la cual se analizó la Recaudación Federal Participable. 📈 🤝 Junto a Fernando Renoir Baca Rivera, titular de la Unidad de Coordinación con Entidades Federativas de  @Hacienda_Mexico  y los titulares de las secretarías de finanzas representantes de las diferentes regiones del país, dialogamos sobre las acciones que han sido imperantes para avanzar en este tema. 📊',
        place: 'Ciudad de México',
        description: '1824651355580961188',
        start: '2024-08-16',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Gracias a la política fiscal que ha instruido el gobernador @juliomenchaca_ , Hidalgo destaca como la entidad con mayor crecimiento equivalente al 7% nominal en las participaciones federales contra lo estimado para el 2024, derivado del incremento en sus ingresos propios, lo anterior conforme a la información proporcionada en la Reunión CCCLIX de la Comisión Permanente de Funcionarios Fiscales.  💯',
        place: 'Ciudad de México',
        description: '1824661172911530417',
        start: '2024-08-16',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '🤝 En mi calidad de coordinadora de la Comisión Permanente de Funcionarios Fiscales reconozco la colaboración entre las entidades, @Hacienda_Mexic0, @SATMX, @ASF_Mexico, @SFP_mx,, @ISSSTE_mx y  el @INDETEC_mx,  para dar seguimiento a los ejes estratégicos del Programa de Trabajo',
        place: 'Ciudad de México',
        description: '1824671391955706000',
        start: '2024-08-16',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'El  gobernador @juliomenchaca_ encabezó la reunión con los presidentes electos de la entidad con el objetivo de dar inicio a una nueva etapa para nuestro estado; trabajando en conjunto, con estrategias que beneficien al pueblo',
        place: 'Pachuca de Soto',
        description: '1828296852774543658',
        start: '2024-08-26',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'El gobernador @juliomenchaca_encabezó la reunión con las y los diputados locales electos del estado para dialogar sobre la importancia de trabajar en unidad por el bienestar del pueblo de #Hidalgo. Sin duda estas acciones son fundamentales para el desarrollo de cada región.',
        place: 'Pachuca de Soto',
        description: '1829017109411582314',
        start: '2024-08-28',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Tuve el honor de acompañar al gobernador @juliomenchaca_en la entrega de reconocimientos a las y los servidores públicos que hacen posible las #RutasDeLaTransformación, como nuestro compañero David Ulises López Hernández, quien a través de su trabajo nos permite acercarnos como Secretaría a cada rincón de Hidalgo, transformando las promesas en realidades tangibles para el pueblo.',
        place: 'Pachuca de Soto',
        description: '1829286501844218050',
        start: '2024-08-29',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Llevamos a cabo una reunión de evaluación de proyectos con miras al cierre del ejercicio 2024, con el titular de @SIPDUS, @_AlejandroSanG y su equipo de trabajo, el propósito es revisar que se estén alcanzando los resultados esperados y contribuyan al progreso económico y social de la entidad.  ',
        place: 'Pachuca de Soto',
        description: '1830689530426597411',
        start: '2024-09-02',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Con @SSP_Hidalgo, @SIPDUS, @Contraloria_Hgo, @SEGOBHidalgo y el @Srio_CespHgo, revisamos el avance de proyectos financiados en coparticipación con recursos federales y estatales del Fondo de Aportaciones para la Seguridad Pública #FASP,  fundamental para garantizar una gestión eficiente y efectiva de este fondo que contribuye a la estrategia de seguridad nacional.',
        place: 'Pachuca de Soto',
        description: '1830789823407305110',
        start: '2024-09-02',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '#LaTransformaciónEstáAquí con resultados, al revisar los avances de los programas sociales como Mujer de maíz, Hilos de identidad, Seguridad alimentaria y Eco estufas del bienestar, con el titular de @cedspi, @PriscoMg, coincidimos en que su ejecución ha sido esencial para el desarrollo económico y la calidad de vida de las comunidades indígenas.',
        place: 'Pachuca de Soto',
        description: '1831102912510247196',
        start: '2024-09-03',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Llevamos a cabo nuestra reunión mensual de gabinete, encabezada por el gobernador @juliomenchaca_. Durante la sesión hablamos sobre las diversas acciones y estrategias que desde cada secretaría hemos implementado para mejorar el bienestar del pueblo de Hidalgo. ',
        place: 'Pachuca de Soto',
        description: '1831491223540134107',
        start: '2024-09-04',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Para la administración del gobernador @juliomenchaca_, la cercanía, honestidad y eficacia son esenciales; trabajamos para mejorar la calidad de vida de las y los hidalguenses. En cada rincón del estado, #LaTransformaciónEstáAquí, palpable y visible. ',
        place: 'Pachuca de Soto',
        description: '1832183531709358171',
        start: '2024-09-06',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Para dar seguimiento a los trabajos de entidades paraestatales, me reuní con el titular de @PlaneacionHgo, @mtellov, donde revisamos los avances en el cumplimiento de sus acciones acorde a la normatividad.',
        place: 'Pachuca de Soto',
        description: '1833652415616155797',
        start: '2024-09-10',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Coordinar esfuerzos y estrategias nos permite garantizar un impacto positivo en los proyectos dirigidos a los grupos prioritarios, es por ello que, con el director del @difhg,  Ricardo Enrique Alvizo Contreras, buscamos que los recursos cumplan con su objetivo.#PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1834055476712993145',
        start: '2024-09-11',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '🇲🇽 Con la presencia de nuestro gobernador @juliomenchaca_miles de hidalguenses asistimos a la #PlazaIndependecia de Pachuca al  #DesfileCívicoMilitar2024  en conmemoración del 214.° aniversario del inicio de la lucha por la Independencia de México. ',
        place: 'Pachuca de Soto',
        description: '1835798295475585373',
        start: '2024-09-16',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En reunión con @VaneEscalanteA, titular de @Salud_Hidalgo y @Felipe_ArreolaT, coordinador estatal del @IMSS_BIENESTAR en #Hidalgo, revisamos los avances de los trabajos de transición del sistema de salud al modelo #MASBIENESTAR para maximizar el impacto de cada acción y asegurar que cada peso invertido tenga un efecto tangible en el bienestar de la población.',
        place: 'Pachuca de Soto',
        description: '1836270398649868692',
        start: '2024-09-17',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En el edificio del @gobiernohidalgo quienes integramos @Hacienda_Hgo participamos  junto al gobernador @juliomenchaca_ en este #SimulacroNacional, el cual nos permitió identificar los puntos de evacuación para una respuesta efectiva en caso de una emergencia real; seguir los protocolos de seguridad establecidos es vital para garantizar el bienestar de todos. ',
        place: 'Pachuca de Soto',
        description: '1836876215266988511',
        start: '2024-09-19',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Llevamos a cabo la Tercera Sesión Ordinaria 2024 del Consejo de Armonización Contable del Estado de Hidalgo #CACEH, en la cual nos acompañó Juan Torres García, titular de la Unidad de Contabilidad Gubernamental de @Hacienda_Mexico y secretario técnico del Consejo Nacional de Armonización Contable, así como Cornelio Rico Arvizu, presidente del Sector Gubernamental en el Instituto Mexicano de Contadores Públicos.',
        place: 'Pachuca de Soto',
        description: '1839445307769106712',
        start: '2024-09-26',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'n esta tercera sesión de Consejo de Armonización Contable del Estado de Hidalgo entregamos a veinte servidoras y servidores públicos los documentos de acreditación del Diplomado en Contabilidad Gubernamental, impartido por el @INDETEC_mx, los cuales reflejan el esfuerzo y dedicación de su trabajo. ',
        place: 'Pachuca de Soto',
        description: '1839479611731931567',
        start: '2024-09-26',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Durante nuestra reunión mensual de gabinete, encabezada por el gobernador @juliomenchaca_, revisamos la información que habrá de presentarse en las próximas comparecencias de las Secretarías que integran al, @gobiernohidalgo.',
        place: 'Pachuca de Soto',
        description: '1839807568052662642',
        start: '2024-09-27',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Me reuní con el encargado de despacho de la @PGJE_Hidalgo, @FFHasbun y su equipo de trabajo, con el objetivo de evaluar el avance de los distintos proyectos previo al cierre del ejercicio 2024. La aplicación eficiente de recursos en estos proyectos contribuyen a fortalecer el sistema de justicia de #ElHidalgoQueQueremos.',
        place: 'Pachuca de Soto',
        description: '1841213973578051693',
        start: '2024-10-01',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '#LaTransformaciónSigueEnHidalgo bajo el liderazgo del gobernador , @juliomenchaca_, quien encabezó la reunión de gabinete este viernes. Evaluamos el avance de las políticas y acciones estratégicas que se implementan en los diversos ámbitos a fin de lograr el bienestar de la población hidalguense.  #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1842359976074879180',
        start: '2024-10-04',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '🤝Garantizar una coordinación efectiva entre las diferentes instancias gubernamentales es fundamental para el desarrollo y bienestar de Hidalgo. Al trabajar en unidad, potencializamos nuestros recursos y esfuerzos #LaTransformaciónSigueEnHidalgo #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1844124430542438877',
        start: '2024-10-09',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Como titular de @Hacienda_Hgo, comparecí ante el pleno de la LXVI Legislatura del @CongresoHidalgo, donde informé que en este segundo año, el @gobiernohidalgo ha logrado una fortaleza económica, finanzas sanas y presupuestos sostenibles, además de un incremento en los ingresos propios, estrategias encaminadas a la consolidación de #ElHidalgoQueQueremos.',
        place: 'Pachuca de Soto',
        description: '1846333694576820473',
        start: '2024-10-15',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Llevamos a cabo una reunión de trabajo con el titular de @OficialiaMa, Orlando Ángeles Pérez, y su equipo, en la que revisamos los proyectos para el ejercicio 2025. Alinear esfuerzos garantiza que los proyectos propuestos contribuyan al desarrollo y transformación de #ElHidalgoQueQueremos.',
        place: 'Pachuca de Soto',
        description: '1846677636040507713',
        start: '2024-10-16',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Ha sido un gusto acompañar a la presidenta del Patronato del Sistema @difhgo, Edda Vite, en su 2º Informe de Actividades. Los resultados presentados reflejan un firme compromiso para mejorar la calidad de vida de las familias en situación de vulnerabilidad. #2doInformeDIFHidalgo #DIFHidalgoEsPorTi',
        place: 'Pachuca de Soto',
        description: '1849309408993669419',
        start: '2024-10-23',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Para construir el #HidalgoQueQueremos, estamos integrado un Paquete Hacendario 2025 que responda a las necesidades de las familias hidalguenses. Junto con el equipo de la @Hacienda_Hgo, revisamos cuidadosamente cada instrumento que regirá la política de ingresos y gastos del próximo año. Como lo ha indicado el gobernador @juliomenchaca_  #PrimeroElPueblo este paquete prioriza el bienestar de las y los hidalguenses.',
        place: 'Pachuca de Soto',
        description: '1852477399860142249',
        start: '2024-11-01',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En nuestra reunión mensual de gabinete junto al gobernador @juliomenchacaz, revisamos los avances y estrategias que las secretarías que integran al @gobiernohidalgo, implementan en beneficio de la población hidalguense, esto con el objetivo de seguir trabajando en unidad en los distintos proyectos que nos permitirán seguir construyendo #ElHidalgoQueQueremos.',
        place: 'Pachuca de Soto',
        description: '1854267572000063671',
        start: '2024-11-06',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Con los titulares de @medioambiente_H, @monicamixtega y de @OficialiaMa, Orlando Ángeles Pérez, con sus respectivos equipos de trabajo, llevamos a cabo una reunión para dialogar sobre los proyectos de la agenda ambiental que  estarán en el Paquete Hacendario 2025. ',
        place: 'Pachuca de Soto',
        description: '1854314368566976563',
        start: '2024-11-06',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Bajo la visión de llevar a #Hidalgo a su máximo potencial y reafirmando la premisa de #PrimeroElPueblo,  continuamos con elaboración y construcción del Paquete Hacendario 2025, enfocado en el compromiso del gobernador @juliomenchaca_ para consolidar el bienestar de todos los hidalguenses.',
        place: 'Pachuca de Soto',
        description: '1855089923042267643',
        start: '2024-11-08',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En representación del gobernador @juliomenchaca_ y en cumplimiento al mandato constitucional, presenté ante la LXVI Legislatura del  @CongresoHidalg, el Paquete Hacendario 2025. Con un presupuesto histórico de más de 71 mil millones de pesos se beneficiará a los sectores más vulnerables, con un incremento en el gasto de inversión del 28% en programas sociales y obra pública. ',
        place: 'Pachuca de Soto',
        description: '1858971618598330378',
        start: '2024-11-19',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Esta tarde visité la Coordinación de Planeación Financiera, un destacado equipo de la @Hacienda_Hgo, a quienes quiero reconocer por su esfuerzo y compromiso. Esta área desempeña un papel fundamental en la elaboración del Paquete Hacendario 2025, un instrumento importante para el desarrollo y bienestar de los hidalguenses.',
        place: 'Pachuca de Soto',
        description: '1859075054631612624',
        start: '2024-11-19',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Realizamos una reunión de trabajo con instituciones bancarias, con el objetivo de explorar condiciones financieras que resulten en un beneficio significativo para nuestra entidad. Esta administración se compromete a buscar una gestión más eficiente y efectiva de los recursos, priorizando la optimización y la aplicación responsable de los mismos.',
        place: 'Pachuca de Soto',
        description: '1859756140084724038',
        start: '2024-11-21',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Evaluar el cierre del ejercicio fiscal es fundamental para garantizar la correcta aplicación de los recursos públicos. Por ello, nos reunimos con el titular de @SIPDUS, @_AlejandroSanG, y su equipo de trabajo para revisar los proyectos de obras públicas en proceso de ejecución y conclusión.',
        place: 'Pachuca de Soto',
        description: '1861181076963574195',
        start: '2024-11-25',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'En la reunión mensual de gabinete, presentamos al gobernador @juliomenchaca_los principales logros y proyectos futuros de cada una de las secretarías del @gobiernohidalgo. Trabajando en unidad y de manera eficiente, estamos transformando #Hidalgo con el objetivo de mejorar la calidad de vida de las y los hidalguenses.',
        place: 'Pachuca de Soto',
        description: '1864822566361157821',
        start: '2024-12-05',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Una de las encomiendas del gobernador @juliomenchaca_es trabajar en unidad y de manera coordinada con el @GobiernoMX,  a fin de impulsar la construcción de #ElHidalgoQueQueremos, es por ello que tuvimos el honor de ser sede de la 53ª Reunión Nacional de Funcionarios Fiscales con @Hacienda_Mexico. Acción que nos permite consolidar una estrecha relación y colaboración entre todas las entidades federativas a fin fortalecer las haciendas públicas del país, en beneficio del pueblo de México. #HidalgoEnElMapa',
        place: 'Pachuca de Soto',
        description: '1865155472606793980',
        start: '2024-12-06',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Fernando Renoir Baca Rivera, titular de la Unidad de Coordinación con Entidades Federativas de @Hacienda_Mexico, externó que desde la federación se reforzarán los lazos de cooperación con los 32 estados, así como con el @INDETEC_mx. En mi carácter de coordinadora de la Comisión Permanente de Funcionarios Fiscales, externé que en este 2024 en materia hacendaria tenemos retos y oportunidades para consolidar las políticas sociales de la presidenta @Claudiashein',
        place: 'Pachuca de Soto',
        description: '1865183936411836618',
        start: '2024-12-06',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Es para mí un orgullo compartirles que he sido reelecta como coordinadora de la Comisión Nacional de Funcionarios Fiscales del periodo 2024 - 2025, nombramiento que representa para mi un gran honor y responsabilidad que continuaré asumiendo con compromiso y entusiasmo. Agradezco profundamente la confianza depositada en su servidora, seguiremos trabajando en unidad para fortalecer las haciendas públicas y alcanzar nuevos logros que beneficien al pueblo de México.',
        place: 'Pachuca de Soto',
        description: '1865190182095360183',
        start: '2024-12-06',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Junto a la presidenta del Patronato del @difhgo, Edda Vite Ramos, llevamos a cabo la última sesión del año. Entre los puntos más relevantes que se abordaron, se encuentran el reporte de la Cena de Gala 2024, así como la planificación de eventos y actividades para recaudar fondos en 2025. Estas actividades son fundamentales para asegurar la continuidad de los programas y proyectos que benefician a los grupos prioritarios del estado.',
        place: 'Pachuca de Soto',
        description: '1867034791159197696',
        start: '2024-12-11',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Para llevar a cabo una revisión del ejercicio fiscal que está próximo a concluir, trabajamos en estrecha colaboración con el titular de @OficialiaMa, Orlando Pérez Ángeles. Durante esta sesión, analizamos  el progreso y los resultados de algunos de los proyectos desarrollados.',
        place: 'Pachuca de Soto',
        description: '1867054505797279948',
        start: '2024-12-11',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Hoy despedimos con profundo cariño a nuestras compañeras y compañeros que inician una nueva etapa en sus vidas como jubilados. En especial a nuestra compañera María del Pilar Espinosa Contreras, quien cumplió 49 años de dedicación, esfuerzo y compromiso en la Dirección General de Administración de la @Hacienda_Hgo Gracias, por su labor y profesionalismo. ¡Les deseamos éxito y felicidad! ',
        place: 'Pachuca de Soto',
        description: '1867345873753325972',
        start: '2024-12-12',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '¡La magia de los Reyes Magos llegó a Pachuca! 💫✨ Con las familias hidalguenses disfrutamos junto al gobernador @juliomenchaca_ y su esposa Edda Vite, una espectacular cabalgata llena de color, música y fantasía. Melchor, Gaspar y Baltasar recorrieron las calles, acompañados de sorprendentes personajes que hicieron de esta noche un recuerdo imborrable para chicos y grandes. #LlegaronLosReyes 👑👑👑',
        place: 'Pachuca de Soto',
        description: '1876137273676234984',
        start: '2025-01-05',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '¡#LlegaronLosReyes! 👑 Alegría, ilusión y emoción 🥰 se vivieron en la tradicional Rosca de Reyes, celebrada por el gobernador @juliomenchaca_ y su esposa, Edda Vite, acompañados de miles de familias hidalguenses. Sin duda, una tradición que siempre fortalece la unión en los hogares. 👏 Fue un placer compartir esta delicia de pan con las niñas y los niños, junto a los personajes de Melchor, Gaspar y Baltasar. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1876362415752855916',
        start: '2025-01-06',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '👌Bajo el liderazgo del gobernador @juliomenchaca_, celebramos la reunión mensual de gabinete reafirmando nuestro compromiso con el trabajo colaborativo y el bienestar de las familias hidalguenses. Junto a las y los titulares de las secretarías del @gobiernohidalgo, nos sumamos con determinación para alcanzar objetivos estratégicos y consolidar #ElHidalgoQueQueremos 🫶, siempre poniendo #PrimeroElPueblo.',
        place: 'Pachuca de Soto',
        description: '1876760666952098181',
        start: '2025-01-07',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Junto al titular de la Operadora de Eventos del Estado de Hidalgo, Marco Antonio Arranzábal Juárez, revisamos los proyectos clave que traerán acciones favorables que serán motivo de disfrute para muchas familias hidalguenses. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1877509112340115726',
        start: '2025-01-09',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '😃Iniciamos la semana con el #LunesCívico 🇲🇽, evento en el que contamos con la distinguida presencia del Teniente Coronel Alejandro Hernández Vázquez, en representación del General de Brigada del Ejército Mexicano, Alfredo Salgado Vargas, Comandante de la 18.ª Zona Militar, así como el Primer Subinspector Alejandro Cruz Nava, representando al Comisario Jefe Julices Julián Tadeo González Calzada, titular de la Coordinación Estatal de la Guardia Nacional en Hidalgo.',
        place: 'Pachuca de Soto',
        description: '1878868019847569896',
        start: '2025-01-13',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '✅Hoy, junto a titulares de las subsecretarías y directores generales de @Hacienda_Hgo, llevamos a cabo una reunión de trabajo centrada en los retos y objetivos clave para el ejercicio fiscal 2025, fortaleciendo la gestión eficiente y transparente de los recursos públicos. 👊Como parte del equipo del gobernador @juliomenchaca_, reafirmamos nuestro compromiso de transformar Hidalgo. Desde nuestras respectivas responsabilidades trabajamos para consolidar #ElHidalgoQueQueremos con acciones que generan bienestar y desarrollo para las familias hidalguenses. #PrimeroElPueblo',
        place: 'Pachuca de Soto',
        description: '1879000058009411937',
        start: '2025-01-13',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '✅💰 Seguir fortaleciendo una administración eficiente, transparente y responsable de los recursos públicos ha sido una de las principales prioridades de nuestro gobernador  @juliomenchaca_. Por ello, llevamos a cabo en coordinación con la @Contraloria_Hgo reuniones de trabajo con diversos entes públicos estatales. Con @SIPDUS, @SECTURHidalgo, @CEAA_HGO, @SSP_Hidalgo, @CAASIM y el Instituto Hidalguense de la Infraestructura Física Educativa, revisamos los cierres de ejercicio fiscal 2024, respectivamente y establecimos estrategias claras para los proyectos que iniciamos en el ejercicio fiscal 2025.',
        place: 'Pachuca de Soto',
        description: '1879298726596075954',
        start: '2025-01-14',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '🩺Con el compromiso de dignificar los servicios de salud en Hidalgo, el gobernador @juliomenchaca_, en colaboración con la Presidenta @Claudiashein, impulsa la transformación del sector mediante los acuerdos del programa IMSS Bienestar. ✅ Esta alianza garantiza atención médica de calidad, poniendo la salud y el bienestar de las familias hidalguenses en el centro de la transformación.',
        place: 'Pachuca de Soto',
        description: '1879393184926908482',
        start: '2025-01-14',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'La administración que encabeza el gobernador @juliomenchaca_optimiza los recursos destinados a la inversión del gasto y garantiza que cada peso se traduzca en beneficios reales para la ciudadanía, por ello continuamos con @Contraloria_Hgo la agenda de reuniones de los entes públicos estatales. Este día estuvo presente  @OficialiaMa, @Bienestar_Hgo, @CulturaHidalgo, @medioambiente_H, @SEGOBHidalgo, @RadioTvHidalgo, @cedspi, @IAAMEH, @_DESCTI, @CJMHoficial y el Consejo Estatal para la Cultura y las Artes de Hidalgo #Cecultah, con quienes realizamos la revisión de los cierres correspondientes al ejercicio fiscal 2024.',
        place: 'Pachuca de Soto',
        description: '1879722339363082469',
        start: '2025-01-15',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '🤝 Con la presencia del subsecretario de  @Hacienda_Mexico, Edgar Amador Zamora y en mi carácter de coordinadora de la Comisión Permanente de Funcionarios Fiscales, llevamos a cabo la CCCLXII reunión, donde presenté a las y los integrantes el plan de trabajo para el periodo 2024- 2025.📈 💰En este segundo año consecutivo que #Hidalgo coordina esta Comisión, en colaboración con la Federación y el @INDETEC_mx,  continuemos con el firme compromiso de trabajar para fortalecer las haciendas públicas del país, acción alineada al proyecto de la presidenta Dra. @Claudiashein.',
        place: 'Ciudad de México',
        description: '1882939570066464942',
        start: '2025-01-24',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Con el objetivo de mejorar los servicios en línea para los contribuyentes, me reuní con los titulares de las subsecretarías de Ingresos, Egresos y de la Coordinación de Tecnologías de la Información  para evaluar y planear estrategias que optimicen los trámites digitales. 🛜✨Seguimos avanzando para ofrecerte un servicio más ágil y accesible. ',
        place: 'Pachuca de Soto',
        description: '1884037519853117466',
        start: '2025-01-27',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '📊En la Subsecretaría de Ingresos de la @Hacienda_Hgo, fue un gusto reunirme con el equipo de la Dirección General de Auditoría Fiscal, cuyo compromiso y esfuerzo han sido clave para fortalecer las estrategias que garantizan una recaudación eficiente.💰✅Gracias a su arduo trabajo, la administración del gobernador @juliomenchaca_ se posiciona entre los primeros lugares en el Tablero Global de Eficiencia Recaudatoria del #SAT, asegurando los recursos necesarios para continuar construyendo #ElHidalgoQueQueremos.',
        place: 'Pachuca de Soto',
        description: '1884357324367880231',
        start: '2025-01-28',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '🤝 Esta mañana, #Hidalgo participa en el “3er. Encuentro de Titulares de las Secretarías de Finanzas: Modelo Hacendario Estatal para la Cohesión Social”, que se lleva a cabo en el estado de #Chihuahua. ✅ Estoy segura de que será un espacio enriquecedor que contribuirá significativamente al fortalecimiento de las haciendas públicas de todo el país.🇲🇽',
        place: 'Chihuahua, Chihuahua',
        description: '1885033187174465989',
        start: '2025-01-30',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: 'Con la presencia de la gobernadora de #Chihuahua, María Eugenia Campos Galván, en la inauguración del “3er Encuentro de Titulares de las Secretarías de Finanzas: Modelo Hacendario Estatal para la Cohesión Social”, hemos contado con la participación de destacados exponentes nacionales e internacionales, quienes han analizado el panorama económico global y la situación fiscal en México. En el foro estuvo presente Fernando Renoir Baca, titular de la Unidad de Coordinación con Entidades Federativas (UCEF) de la @Hacienda_Mexico, quien abordó el tema: “Perspectiva de las haciendas públicas locales para el ejercicio fiscal 2025 en el ámbito de la coordinación fiscal”. Asimismo, se contó con la participación de Francisco André, embajador de la Unión Europea en México. #ModeloDeCohesiónSocial #FuncionariosFiscales',
        place: 'Chihuahua, Chihuahua',
        description: '1885209059332161953',
        start: '2025-01-31',
        color: 'var(--wine)',
    },
    { 
        id: 0,
        title: '#Hidalgo se suma a los esfuerzos coordinados para fortalecer las políticas públicas orientadas a reducir la desigualdad social, en el marco del "3er Encuentro de Titulares de las Secretarías de Finanzas: Modelo Hacendario Estatal para la Cohesión Social", celebrado en #Chihuahua durante esta segunda jornada de trabajo.',
        place: 'Chihuahua, Chihuahua',
        description: '1885406954413068644',
        start: '2025-01-31',
        color: 'var(--wine)',
    }
    // { 
    //     id: 0,
    //     title: '',
    //     place: 'Pachuca de Soto',
    //     description: '',
    //     start: '2025-00-00',
    //     color: 'var(--wine)',
    // }
];
